@import "../../variables";
@import "../../mixins";
@import "../../mq";

.list-block {
  &__list {
    list-style-type: none;
    counter-reset: list-counter;
    margin: 0;
    padding: 0;

    li {
      border-bottom: 1px solid $border-color;
      display: flex;
      padding: $space 0;
      counter-increment: list-counter;
      flex-wrap: wrap;

      @include mq($from: XL) {
        padding: $space * 2 0;
      }

      &::before {
        content: "0" counter(list-counter);
        font-weight: bold;
        @include flex-vertical-center;
        padding: 0;

        @include mq($from: XL) {
          padding: 0 0 0 $space * 2;
        }
      }
    }
  }

  &__heading {
    @include flex-vertical-center;
    font-size: 1.125rem;
    line-height: 1.5rem;
    margin-bottom: 0;
    position: relative;

    @include mq($from: XL) {
      @include headingFont($h5-lg);
      @include flex-vertical-center;
      margin-bottom: 0;
    }

    .panel--theme-medium &,
    .panel--theme-dark & {
      color: $white;
    }
  }

  &__divider {
    width: 50px;
    text-align: center;

    @include mq($from: XL) {
      width: 100px;
    }

    svg {
      display: inline-block;
      width: $space;
      height: $space * 2.5;
    }
  }

  &__actions {
    margin-left: auto;
    width: 100%;
    margin-top: $space;

    @include mq($from: M) {
      width: auto;
      margin-top: 0;
    }

    .btn {
      padding: $space/2 $space * 1.5;
    }
  }
}
