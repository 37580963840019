.bot-eb-widget.bot-right.bot-show {
  background-color: $white;
  box-shadow: -60px 0 60px 0 rgba(0, 0, 0, 0.15);

  &::before {
    background-color: $white;
    content: "";
    display: block;
    height: 100%;
    left: -1rem;
    position: absolute;
    width: 1rem;
  }
}
