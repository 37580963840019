@import "../../variables";
@import "../../mixins";
@import "../../mq";

%panel {
  padding: $space * 2 0;

  @include mq($from: L) {
    padding: $space * 4 0;
  }

  .panel--theme-none:not(.panel--full-width) + .panel--theme-none &,
  .panel--theme-light:not(.panel--full-width) + .panel--theme-light &,
  .panel--theme-medium:not(.panel--full-width) + .panel--theme-medium &,
  .panel--theme-dark:not(.panel--full-width) + .panel--theme-dark & {
    padding-top: 0;

    @include mq($from: L) {
      padding-top: 0;
    }
  }

  .panel--no-top-padding & {
    padding-top: 0;
  }

  .panel--no-bottom-padding & {
    padding-bottom: 0;
  }

  .panel--extra-top-padding & {
    padding-top: $space * 3;

    @include mq($from: L) {
      padding-top: $space * 6;
    }
  }

  .panel--extra-bottom-padding & {
    padding-bottom: $space * 3;

    @include mq($from: L) {
      padding-bottom: $space * 6;
    }
  }
}

.panel {
  position: relative;

  &::before {
    background-color: $primary-highlight-color;
    color: $white;
    content: none; // Set this to attr(data-internal) to show internal names on the front end
    display: inline-block;
    font-size: 12px;
    line-height: 1;
    padding: 4px 8px 5px;
    position: absolute;
    top: 0;
    z-index: 9998;
  }

  &__inner {
    position: relative;
    z-index: 1;
  }

  &--theme-light {
    background-color: $grey-lightest;
  }

  &--theme-medium {
    background-color: $grey-medium;
    color: $white;
  }

  &--theme-dark {
    background-color: $secondary-highlight-color;
    color: $white;
  }

  &--halign-center {
    text-align: center;
  }

  &--full-width {
    .panel__inner {
      padding-bottom: 0;
      padding-top: 0;
    }

    .constrain {
      max-width: none;
      width: 100%;
    }
  }

  &--reduced-width .constrain {
    max-width: $maxContent;
  }
}

.page {
  min-height: $space * 8;
}
