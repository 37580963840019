.course-status {
  &__wrapper {
    border-bottom: 1px solid $border-color;
    position: relative;
  }

  &__button {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
    background: $white;
    border: 0;
    text-transform: uppercase;
    font-family: $heading-font;
    font-size: 1rem;
    line-height: 1.5rem;
    transition: $transition-timing;
    padding: $space 5%;

    @include mq($from: L) {
      width: 70%;
    }

    @include mq($from: XXL) {
      width: 50%;
      padding: $space 0;
      font-size: 1.5rem;
      line-height: 2rem;
    }

    &.open {
      .course-status__arrow {
        transform: rotate(-180deg);
      }
    }

    &:hover,
    &:active,
    &:focus {
      color: $grey-light;
      background: transparent;
    }
  }

  &__notes {
    height: 22px;
    width: 20px;
    margin-top: -5px;
    margin-right: $space;
  }

  &__arrow {
    width: 20px;
    height: 10px;
    fill: transparent;
    margin-top: 8px;

    @include mq($from: XXL) {
      margin-top: 12px;
    }
  }

  &__dropdown {
    background: $grey-mid;
    padding: $space * 2 5%;
    width: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    z-index: 100;
    transition: $transition-speed;

    @include mq($from: L) {
      width: 70%;
    }

    @include mq($from: XXL) {
      width: 50%;
      padding: $space * 3 $space * 4;
    }

    &.open {
      opacity: 1;
      pointer-events: all;
    }

    h2 {
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }

  &__table {
    border-top: 1px solid $grey-light;
    border-bottom: 1px solid $grey-light;

    tr {
      td {
        border-bottom: 0;
        padding: $space/2 0;
        font-size: 1.125rem;
        &:after {
          display: none;
        }
      }
      &:first-of-type {
        td {
          padding-top: $space;
        }
      }

      &:last-of-type {
        td {
          padding-bottom: $space;
        }
      }
    }

    &__status {
      text-align: right;
      text-transform: uppercase;
      font-weight: 700;
    }
  }
}
