.accordion-quote {
  margin: 0 auto;
  text-align: center;

  @include mq($from: XL) {
    width: 70%;
  }

  @include mq($from: XXL) {
    width: 60%;
  }

  &__image {
    width: $space * 6;
    height: $space * 6;
    border-radius: 100%;
    overflow: hidden;
    display: inline-block;
    margin-bottom: $space * 2;

    img {
      @include object-fit(cover, center);
      display: block;
      height: 100%;
      margin: 0;
      padding: 0;
      transition: opacity $transition-speed $transition-timing;
      width: 100%;
    }
  }

  h2 {
    @include headingFont($h5-sm);
    margin-bottom: $space * 2;

    @include mq($from: L) {
      @include headingFont($h2-sm);
    }
  }

  h3 {
    font-size: 1.25rem;
    @include mq($from: L) {
      @include headingFont($h5-sm);
    }
  }

  .btn {
    padding-left: $space * 5;
    padding-right: $space * 5;
    margin: $space * 2 0;
  }

  &__more {
    color: $black;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    display: block;
    margin-top: $space;

    &:before {
      content: "More in this section";
    }

    &.open {
      &:before {
        display: none;
      }

      &:after {
        content: "Close section";
      }

      svg {
        transform: rotate(-180deg);
      }
    }

    &:hover,
    &:active,
    &:focus {
      color: $black;
      text-decoration: underline;
    }

    svg {
      width: 27px;
      height: 25px;
      fill: $foxhills-black;
      display: block;
      margin: 10px auto;
    }
  }

  &__content {
    display: none;
  }

  .panel--theme-medium &,
  .panel--theme-dark & {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $white;
    }

    a {
      color: $white;
      border-color: $white;
    }

    svg {
      stroke: $white;
    }

    a.btn {
      &--primary {
        background-color: $secondary-button-color;
        border-color: $secondary-button-color;
        color: $primary-button-color;

        &:hover {
          background-color: transparent;
          color: $secondary-button-color;
        }
      }
    }
  }
}
