@import "../../variables";
@import "../../mixins";
@import "../../mq";

.quote-block {
  text-align: center;
  position: relative;

  &__quote-icon {
    display: block;
    fill: var(--theme-color);
    width: $space * 3;
    height: $space * 2.5;
    margin: 0 auto;
    z-index: 1;

    &:last-child {
      transform: rotate(180deg);
    }

    .panel--theme-dark & {
      fill: $white;
    }

    .quote-block--hide-quotes & {
      display: none;
    }
  }

  &__quotes {
    padding: 44px 34px 80px;
    margin-bottom: 0 !important;
    border: 1px solid $border-color;

    @include mq($from: XL) {
      padding-left: 100px;
      padding-right: 100px;
    }
    @include mq($from: XXL) {
      padding-left: 144px;
      padding-right: 144px;
    }
  }

  &__quote {
    display: inline-flex !important;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    padding: 40px 0;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid $border-color;
    position: relative;

    //  @include mq($from: XL) {
    //    width: 70%;
    //  }

    p {
      color: $primary-highlight-color;
      //font-family: $heading-font;
      font-style: normal;
      font-weight: 400;

      @include mq($from: M) {
        font-size: 1.5rem;
        line-height: 1.5;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .panel--theme-medium &,
      .panel--theme-dark & {
        color: $white;
      }
    }

    .quote-block--hide-quotes & {
      padding: 0;
    }
  }

  &__quotation {
    margin: 0 auto;
    max-width: 800px;
  }

  &__paging {
    position: absolute;
    left: 34px;
    bottom: 40px;
    font-size: 1rem;
    font-family: $heading-font;

    @include mq($from: XL) {
      left: 100px;
    }
    @include mq($from: XXL) {
      left: 144px;
    }
  }

  &__name {
    color: $primary-highlight-color;
    font-weight: 700;

    .panel--theme-medium &,
    .panel--theme-dark & {
      color: $white;
    }
  }

  .content-panel__row--two-column .content-panel__column & {
    @include mq($from: M) {
      padding: $space * 2;
    }

    @include mq($from: XXL) {
      padding: $space * 4;
    }
  }

  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    height: inherit !important;

    > div {
      height: 100%;
    }
  }

  .slick-dots {
    bottom: 37px;
    width: auto;
    left: 50%;
    transform: translateX(-50%);

    button:before {
      height: 15px;
      width: 15px;
    }
  }

  .slick-dots li button:before {
    background-color: $white;
    border: 2px solid $foxhills-black;
    content: "";
    border-radius: 100%;
  }

  .slick-dots li.slick-active button:before {
    background-color: $foxhills-black;
  }

  .slick-prev {
    display: none;
    opacity: 0;
  }

  .slick-next {
    bottom: 40px;
    top: auto;
    border: 0;
    right: 34px;
    color: $foxhills-black;
    transform: none;
    font-size: 1rem;
    font-family: $heading-font;
    line-height: normal;
    border-bottom: 1px solid $foxhills-black;
    width: auto;
    height: auto;
    display: block;
    content: "Next";
    z-index: 300;
    transition: $transition-speed;

    @include mq($from: XL) {
      right: 100px;
    }
    @include mq($from: XXL) {
      right: 144px;
    }

    &:hover,
    &:active,
    &:focus {
      color: $grey-medium;
    }
  }
}
