@import "../../variables";
// @import "../../mixins";
@import "../../mq";

.form-block {
  .content-panel__row--two-column .content-panel__column & {
    @include mq($from: M) {
      padding: $space * 2;
    }

    @include mq($from: XXL) {
      padding: $space * 4;
    }
  }
}
