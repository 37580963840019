.venue-card {
  display: inline-flex !important;
  flex-direction: column;
  justify-content: space-between;
  max-width: 90vw;
  height: 100%;
  padding: 26px;
  border: 1px solid $border-color;

  @include mq($from: XXXL) {
    max-width: 1280px;
  }

  .fancybox-slide & {
    height: auto;
    padding: 24px;

    .fancybox-button {
      width: 30px;
      height: 30px;
      padding: 4px;
    }

    @include mq($from: XL) {
      padding: 36px 0 36px 36px;

      .fancybox-button {
        width: 36px;
        height: 36px;
      }
    }

    @include mq($from: XXXL) {
      padding: 44px 0 44px 44px;

      .fancybox-button {
        width: 44px;
        height: 44px;
        padding: 6px;
      }
    }

    .venue-card__image-container {
      &:hover {
        .venue-card__image {
          opacity: 0.5;
          transition: opacity 400ms ease-out;
        }

        .venue-card__image-btn {
          opacity: 1;
          transform: translate(-50%, -50%);
          transition: all 400ms ease-out;
          pointer-events: all;
        }
      }
    }

    .venue-card__media {
      flex-shrink: 0;
    }

    .venue-card__media,
    .venue-card__description {
      display: block;
    }

    .venue-card__meta {
      @include mq($from: M) {
        padding-bottom: 80px;
        overflow-x: hidden;
        overflow-y: auto;

        &::after {
          content: "";
          width: 100%;
          height: 100px;
          position: absolute;
          right: 44px;
          bottom: 0;
          background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 15%,
            rgba(255, 255, 255, 0) 100%
          );
        }
      }

      @include mq($from: XL) {
        padding-right: 36px;
        max-height: 510px;
      }

      @include mq($from: XXXL) {
        padding-right: 44px;
        max-height: 690px;
      }
    }
    .venue-card__btn-container {
      display: none;
    }
  }

  &__inner {
    position: relative;
    @include mq($from: M) {
      display: flex;
    }
  }

  &__media {
    display: none;
  }

  &__image-container {
    position: relative;
  }

  &__image {
    margin: 0;
    width: 100%;
    transition: opacity 400ms ease-in;
  }

  &__image-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0;
    transform: translate(-50%, -36%);
    transition: all 400ms ease-in;
    pointer-events: none;
  }

  &__heading {
    padding: 20px 0;
    border-bottom: 1px solid $border-color;
  }

  &__description {
    display: none;
  }

  &__status {
    padding: 20px 0;
    border-bottom: 1px solid $border-color;

    &--primary {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
    }
  }

  &__btn-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}
