@import "variables";
@import "mixins";
@import "mq";

.theme--foxhills {
  --theme-color: #{$foxhills-black};
  --theme-color-contrast: #{$black};
}

.theme--farleigh {
  --theme-color: #{$farleigh-blue};
  --theme-color-contrast: #{$farleigh-blue};
}
