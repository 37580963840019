.contact-block {
  border-top: 1px solid $border-color;
  padding-top: $space * 2;

  @include mq($from: L) {
    padding-top: $space * 4;
  }

  @include mq($from: L) {
    margin: 0 auto;
    width: 80%;
  }

  h2 {
    @include headingFont($h4-sm, $h4-lg);
  }

  p {
    &:last-child {
      margin-bottom: 0;
    }

    @include mq($from: M) {
      font-size: 1.125rem;
      line-height: 1.6875rem;
    }
  }

  a {
    @extend %link;
    font-weight: 700;
  }

  .panel--theme-medium &,
  .panel--theme-dark & {
    border-top: 0;
    padding-top: 0;

    @include mq($from: L) {
      padding-top: 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $white;
    }

    a {
      color: $white;

      &::after {
        background-color: $white;
      }
    }
  }
}
