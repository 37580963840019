// @import "../../variables";
// @import "../../mixins";
// @import "../../mq";

.video-block {
  position: relative;

  &__cover {
    display: block;
    margin: 0;
    width: 100%;
  }

  &__caption {
    background: rgba(0, 0, 0, 0.5);
    padding: $space * 1.5 0;
    text-align: center;
    position: absolute;
    width: 100%;
    bottom: 0;
  }

  &__video {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
