@import "../variables";
@import "../mixins";
@import "../mq";

.hero {
  background-color: $white;
  height: calc(100vh - #{$mobile-header-height});
  max-height: $space * 15;
  padding: $baseline 0;
  position: relative;

  @include mq($from: XL) {
    height: calc(100vh - #{$header-height});
    max-height: $space * 25;
  }

  &__image-container {
    height: 100%;
    pointer-events: none;
    position: relative;
    z-index: 1;
  }

  &__video-container {
    width: 100vw;
    height: 56.25vw;
    min-height: 500px;
    min-width: 889px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    z-index: 1;

    @include mq($from: XL) {
      min-height: calc(100vh - #{$header-height});
      min-width: calc(calc(100vh - #{$header-height}) * 1.77);
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__slide {
    position: relative;
    height: calc(100vh - #{$mobile-header-height});
    max-height: $space * 15;
    margin-bottom: -6px; // Account for weird Slick margin bug

    @include mq($from: XL) {
      height: calc(100vh - #{$header-height});
      max-height: $space * 25;
    }

    .hero--home & {
      max-height: $space * 25;

      @include mq($from: XL) {
        max-height: $space * 40;
      }
    }
  }

  &__image {
    @include object-fit(cover, center);
    display: block;
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    left: 50%;
    padding: 0 $space 36px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: 1;

    @include mq($from: L) {
      padding: 0 $space * 2 58px;
      width: 90%;
    }

    @include mq($from: XXL) {
      width: 75rem;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  &__heading {
    @include headingFont(2rem, 4.5rem);
    color: $white;
    width: 100%;
    margin: $space auto 0;
    text-shadow: 0px 0px 20px #000000;
    text-align: center;

    @include mq($from: XL) {
      margin: $space * 2 auto 0;
    }

    &:last-child {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &__links {
    margin: $space 0;

    @include mq($from: XL) {
      margin: $space * 2 auto;
    }

    @media (max-height: 760px) {
      margin-top: $space;
      margin-bottom: $space;
    }

    .btn {
      background: $secondary-button-color;
      border-color: $secondary-button-color;
      color: var(--theme-color);
      box-shadow: 0 0 14px rgba(0, 0, 0, 0.4);

      &:hover {
        background: var(--theme-color);
        border-color: var(--theme-color);
        color: $secondary-button-color;
      }
    }
  }

  &__video {
    margin: $space 0;

    @include mq($from: XL) {
      margin: $space * 2 0;
    }

    @media (max-height: 760px) {
      margin: $space 0;
    }
  }

  &__news {
    background: $white;
    display: flex;
    justify-content: space-between;
    margin: 0 auto 36px;
    width: 100%;
    box-shadow: 0 0 14px rgba(0, 0, 0, 0.4);

    @include mq($from: XL) {
      margin-bottom: 50px;
      max-width: 45rem;
    }

    @media (max-height: 760px) {
      display: none;
    }

    h2 {
      background-color: $grey-mid;
      font-size: 1rem;
      text-transform: uppercase;
      padding: 9px 1rem 8px;
      margin-bottom: 0;

      @include mq($from: XL) {
        font-size: 1.5rem;
        padding: 12px 2rem 10px;
      }
    }
  }

  &__news-article {
    flex: 1;

    a {
      align-items: center;
      display: flex;
      height: 100%;
      padding: 0 1rem;
      text-align: left;
    }
  }

  &--has-slides {
    height: auto;
    max-height: none;
    padding: 0;
  }
}

.hero-slider {
  &.slick-dotted.slick-slider {
    margin-bottom: 0;
  }

  .slick-prev,
  .slick-next {
    z-index: 100;
    border: 0;
    bottom: auto;
    top: 50%;
    height: 48px;
    width: 24px;
    filter: drop-shadow(0px 0px 4px #000000);

    @include mq($from: L) {
      bottom: $space;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  .slick-prev {
    left: $space * 2;
    background: url(/img/dist/sprite/slider-arrow-left.svg) no-repeat;
    background-size: contain;
  }

  .slick-next {
    right: $space * 2;
    background: url(/img/dist/sprite/slider-arrow-right.svg) no-repeat;
    background-size: contain;
  }

  .slick-dots {
    bottom: $space;
    z-index: 1;

    @include mq($from: XL) {
      bottom: $space * 2;
    }

    li {
      button::before {
        background-color: transparent;
        border-radius: 100%;
        border: 1px solid $white;
        content: "";
        height: 12px;
        opacity: 1;
        width: 12px;
        box-shadow: 0 0 14px rgba(0, 0, 0, 0.75);
      }

      &.slick-active button:before {
        background-color: $white;
      }
    }
  }
}
