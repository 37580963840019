@import "../variables";
// @import "../mixins";
@import "../mq";

%btn,
input[type="submit"] {
  background-color: var(--theme-color);
  border-radius: 0;
  border: 2px solid var(--theme-color);
  color: $secondary-button-color;
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  height: auto;
  min-height: 0;
  padding: $space * 0.5 $space;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: all $transition-speed $transition-timing;
  vertical-align: middle;

  @include mq($from: L) {
    padding: $space $space * 2;
  }

  &:hover {
    background-color: $secondary-button-color;
    border-color: var(--theme-color);
    color: var(--theme-color);
  }

  &:active {
    background-color: $grey-medium;
    color: $white;
  }

  &:focus {
    background-color: $grey-dark;
    color: $white;
    outline-width: 0;
    outline-offset: 0;
  }

  @include mq($from: M) {
    width: auto;
  }

  .panel--theme-dark & {
    color: var(--theme-color);
    background: $secondary-button-color;
    border-color: $secondary-button-color;

    &:hover {
      background-color: transparent;
      color: $secondary-button-color;
    }
  }

  .panel--theme-medium & {
    color: $tertiary-highlight-color;
    background: $secondary-button-color;
    border-color: $secondary-button-color;

    &:hover {
      background-color: transparent;
      color: $secondary-button-color;
    }
  }
}

.btn,
span.btn > a,
a > span.btn {
  @extend %btn;

  @include mq($from: M) {
    &.btn--fw {
      width: 100%;
    }
  }
}

span.btn {
  border: 0;
  padding: 0;

  &::after {
    content: none;
  }
}

// Primary buttons
a.btn--primary,
input.btn--primary,
button.btn--primary,
.btn--primary > a {
  @extend %btn;
  background: var(--theme-color);
  color: $secondary-button-color;
}

// Secondary buttons
a.btn--secondary,
input.btn--secondary,
button.btn--secondary,
.btn--secondary > a {
  @extend %btn;
  background-color: transparent;
  border-color: var(--theme-color);
  color: var(--theme-color);

  &:hover {
    background-color: $primary-highlight-color;
    color: $secondary-button-color;
  }
}

a.btn--dark {
  background: var(--theme-color);
  color: $white;

  &:hover {
    border-color: var(--theme-color);
    background-color: transparent;
    color: var(--theme-color);
  }
}

// Tertiary (outlined) buttons
a.btn--tertiary,
input.btn--tertiary,
button.btn--tertiary,
.btn--tertiary > a {
  @extend %btn;
  background-color: $white;
  border-color: $primary-highlight-color;
  color: $primary-highlight-color;

  &:hover {
    background-color: $primary-highlight-color;
  }
}

// Membership buttons

.btn {
  &__price {
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    padding: $space/2 0 $space/2 $space;
    margin-left: $space;
  }

  &__small {
    font-size: 0.8rem;
    font-weight: 500;
  }
}
