.venue-media {
  width: 300px;
  height: 392px;
  margin: 0 auto 74px;
  box-sizing: content-box;
  position: relative;
  z-index: 2;

  @include mq($from: M) {
    margin-right: 30px;
    margin-left: 0;
    margin-bottom: 0;
  }

  @include mq($from: XL) {
    width: 350px;
    height: 458px;
    margin-right: 44px;
  }

  @include mq($from: XXXL) {
    width: 500px;
    height: 654px;
  }

  .slick-prev,
  .slick-next {
    bottom: -46px;
    top: auto;
    font-size: 1rem;
    font-family: $heading-font;
    line-height: normal;
    border: none;
    border-bottom: 1px solid $primary-text-color;
    color: $primary-text-color;
    width: auto;
    height: auto;
    min-height: unset;
    padding: 4px 0;
    display: inline-block;
    transform: none;
    z-index: 2;
  }
  .slick-prev {
    left: 0;
  }
  .slick-next {
    right: 0;
  }
  .slick-disabled {
    color: $grey-medium;
    border-color: $grey-medium;
  }

  &__image-container {
    position: relative;
    padding-bottom: 46px;

    &:hover {
      .venue-media__image {
        opacity: 0.5;
        transition: opacity 400ms ease-out;
      }

      .venue-media__image-btn {
        opacity: 1;
        transform: translate(-50%, -50%);
        transition: all 400ms ease-out;
      }
    }
  }
  &__image {
    margin: 0;
    transition: opacity 400ms ease-in;
  }
  &__image-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0;
    transform: translate(-50%, -36%);
    transition: all 400ms ease-in;
  }
  &__counter {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    font-family: $heading-font;
  }
}
