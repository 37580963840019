.booking-row {
  border-bottom: 1px solid $border-color;
  margin-bottom: 80px;

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0 20px;

    @include mq($from: XL) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 20px 0 20px;
    }
  }

  // &__image {
  //   display: none;
  //   margin: 0;

  //   @include mq($from: XL) {
  //     display: block;
  //   }
  // }

  &__prompt {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include mq($from: XL) {
      flex-direction: row;
    }

    svg,
    img {
      display: block;
      margin: 0 0 14px 0;

      @include mq($from: XL) {
        margin: 0 20px 0 0;
      }
    }
  }

  &__heading {
    margin-bottom: 14px;
    @include mq($from: XL) {
      margin: 0;
    }
  }

  &__email,
  &__phone {
    display: block;
    padding: 4px 10px;
  }
  &__contact {
    display: flex;
    margin-bottom: 20px;
    a:first-of-type {
      border-right: 1px solid $border-color;
    }
    a:last-of-type {
      border-right: none;
    }

    @include mq($from: XL) {
      margin: 0;
    }
  }
}
