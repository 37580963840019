@import "../../variables";
@import "../../mixins";
@import "../../mq";

.signpost-block {
  &__items {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: $space;
    padding-top: $space;

    @include mq($from: M) {
      margin: 0 -5% -#{$space * 2} 0;
      padding-top: $space * 4;
    }

    @include mq($from: XXL) {
      margin: 0 5% -#{$space * 4};
      padding-top: $space * 2;
    }

    &--as-carousel {
      display: block;

      @include mq($from: XL) {
        margin-left: -$space * 0.5;
        width: calc(100% + #{$space});
      }

      &::before {
        content: none;
      }

      &.signpost-block__items {
        margin: 0;
        padding: 0;
      }

      .slick-list {
        @include mq($from: M) {
          margin: 0 -$space;
          padding: 0 $space;
        }
      }

      .slick-slide {
        @include mq($from: M) {
          margin: 0 $space;
        }
      }

      .signpost-block__item {
        border: 0;
        margin: 0;
        width: auto;
      }

      .signpost-block__content {
        padding: $space;
        margin: 0;
        width: 100%;
      }

      .signpost-block__image-container {
        margin: 0;
        width: 100%;
      }

      .slick-prev,
      .slick-next {
        z-index: 100;
        border: 0;
        top: 50%;
        height: 48px;
        width: 24px;

        &:hover {
          opacity: 0.7;
        }
      }

      .slick-prev {
        left: -$space;
        background: url(/img/dist/sprite/slider-arrow-left-black.svg) no-repeat;
        background-size: contain;
        border: 0;

        @include mq($from: XXXL) {
          left: -$space * 2;
        }
      }

      .slick-next {
        right: -$space;
        background: url(/img/dist/sprite/slider-arrow-right-black.svg) no-repeat;
        background-size: contain;
        border: 0;

        @include mq($from: XXXL) {
          right: -$space * 2;
        }
      }

      .slick-dots {
        bottom: -$space * 2;

        button:before {
          height: 12px;
          width: 12px;
          opacity: 1;
        }
      }

      .slick-dots li button:before {
        background-color: $white;
        border: 1px solid rgba(13, 6, 11, 0.3);
        content: "";
        border-radius: 100%;
      }

      .slick-dots li.slick-active button:before {
        background-color: $black;
      }
    }

    .content-panel__row--two-column & {
      display: block;
    }
  }

  &__item {
    border: 1px solid $border-color;
    display: flex;
    flex-direction: column;
    margin-bottom: $space * 3;
    position: relative;
    width: 100%;

    @include mq($from: M) {
      margin: 0 5% $space * 6;
      width: 40%;
    }

    @include mq($from: L) {
      margin-bottom: $space * 8;
    }

    &:last-child {
      @include mq($until: M) {
        margin-bottom: 0;
      }
    }

    &:nth-child(odd) {
      @include mq($from: M) {
        flex-direction: column-reverse;
      }
    }

    .panel--theme-medium &,
    .panel--theme-dark & {
      border: 0;
    }

    .content-panel__row--two-column & {
      width: auto;

      @include mq($from: M) {
        width: auto;
      }
    }
  }

  &__image-container {
    height: auto;
    margin: #{-$space} 0 0 #{-$space * 2};
    padding-left: $space;
    width: calc(100% + #{$space});

    @include mq($from: M) {
      margin: 0 0 #{-$space * 2} #{-$space * 2};
      padding: 0;
      width: 98%;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .signpost-block__item:nth-child(even) & {
      @include mq($from: M) {
        margin: #{-$space * 2} 0 0 #{-$space * 2};
      }
    }
  }

  &__image {
    @include object-fit(cover, center);
    display: block;
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
    z-index: 2;
    position: relative;
  }

  &__content {
    background-color: $white;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: #{-$space} 0 #{$space} #{-$space * 2};
    padding: $space;
    position: relative;
    width: calc(90% + #{$space * 2});
    z-index: 2;

    @include mq($from: M) {
      margin-top: -$space * 2;
    }

    @include mq($from: XXL) {
      margin-bottom: $space * 2;
      margin-top: -44px;
      width: 90%;
    }

    @include mq($from: XXXL) {
      width: 85%;
    }

    p:last-child {
      margin-bottom: 0;
    }

    .signpost-block__item:nth-child(even) & {
      @include mq($from: M) {
        margin: #{$space} 0 #{-$space * 3} #{-$space * 2};
      }

      @include mq($from: XXL) {
        margin-top: $space * 2;
      }
    }
  }

  &__buttons {
    .btn {
      margin-top: $space * 0.5;

      .panel--theme-medium & {
        background: $primary-highlight-color;

        &:hover,
        &:active,
        &:focus {
          background: $secondary-highlight-color;
        }
      }
    }
  }

  .panel--theme-medium &,
  .panel--theme-dark & {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $white;
    }

    a {
      color: $white;
      border-color: $white;
    }

    svg {
      stroke: $white;
    }

    a.btn {
      background-color: $white;
      color: $foxhills-black;
      border-color: $white;

      &:hover {
        background-color: transparent;
        color: $secondary-button-color;
      }

      &--primary {
        background-color: $white;
        color: $foxhills-black;
        border-color: $white;

        &:hover {
          background-color: transparent;
          color: $secondary-button-color;
        }
      }
    }

    .signpost-block__item {
      border: 1px solid rgba(255, 255, 255, 0.4);
    }
  }

  .panel--theme-dark & {
    .signpost-block__heading,
    .signpost-block__buttons {
      background: transparent;
    }
    .signpost-block__description p {
      background-color: $foxhills-black;
      color: $white;
    }

    .signpost-block__content {
      background-color: $foxhills-black;
    }
  }

  .panel--theme-medium & {
    .signpost-block__content {
      background-color: $grey-medium;
    }
  }

  .panel--theme-light & {
    .signpost-block__heading,
    .signpost-block__buttons {
      background: $grey-lightest;
    }
    .signpost-block__description p {
      background-color: $grey-lightest;
    }
  }

  .content-panel__row--two-column .content-panel__column & {
    @include mq($from: M) {
      padding: $space * 2;
    }

    @include mq($from: XXL) {
      padding: $space * 4;
    }
  }
}
