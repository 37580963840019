.testimonials {
  .panel--theme-medium &,
  .panel--theme-dark & {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $white;
    }

    a {
      color: $white;
      border-color: $white;
    }

    .slick-dots li.slick-active button:before {
      background-color: $white;
      border-color: $white;
    }

    .slick-dots li button:before {
      border-color: $white;
    }

    svg {
      path {
        fill: $white;
      }
    }

    a.btn {
      &--primary {
        background-color: $secondary-button-color;
        border-color: $secondary-button-color;
        color: $primary-button-color;

        &:hover {
          background-color: transparent;
          color: $secondary-button-color;
        }
      }
    }

    .testimonial-slider .slick-next {
      color: $white;
      border-color: $white;
    }

    .testimonial-slider__quote {
      background: url(/img/dist/sprite/icon-quotes-white.svg) no-repeat;
    }
  }
}

.testimonial-slider {
  &__wrapper {
    border: 1px solid $border-color;
    padding: $space * 2;

    @include mq($from: XL) {
      padding: $space * 5 10%;
    }

    .pagingInfo {
      font-family: $heading-font;
      font-size: 1.25rem;
      line-height: 1.75rem;

      @include mq($from: XL) {
        font-size: 1.5rem;
        line-height: 2rem;
      }
    }
  }

  &__title {
    text-align: center;
    @include headingFont($h2-sm);
    margin-bottom: $space * 3;
  }

  &__slide {
    text-align: center;
    margin: 0;
    padding: 0 0 $space * 3;

    @include mq($from: XL) {
      padding: 0 $space * 3 $space * 3;
    }

    svg {
      width: 70px;
      height: 54px;
      margin-bottom: $space * 2.5;
    }

    p {
      font-size: 1.125rem;
      line-height: 2.4rem;
      margin: 0 auto $space;

      @include mq($from: XL) {
        width: 70%;
        font-size: 1.5rem;
        line-height: 2.625rem;
      }
    }
  }

  &__quote {
    display: inline-block;
    height: 54px;
    width: 70px;
    background: url(/img/dist/sprite/icon-quotes.svg) no-repeat;
    background-size: contain;
    margin-bottom: $space;
  }

  .slick-dots {
    bottom: -60px;
    border-top: 1px solid $border-color;
    padding-top: 34px;

    button:before {
      height: 15px;
      width: 15px;
    }
  }

  .slick-dots li button:before {
    background-color: $white;
    border: 2px solid $foxhills-black;
    content: "";
    border-radius: 100%;
  }

  .slick-dots li.slick-active button:before {
    background-color: $foxhills-black;
  }

  .slick-prev {
    display: none;
    opacity: 0;
  }

  .slick-next {
    bottom: -83px;
    top: auto;
    border: 0;
    right: 0;
    color: $foxhills-black;
    font-size: 1.25rem;
    line-height: 1.75rem;
    border-bottom: 1px solid $foxhills-black;
    padding-bottom: 10px;
    width: auto;
    height: auto;
    display: block;
    content: "Next";
    z-index: 300;

    @include mq($from: XL) {
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }
}
