.nav-tabs {
  width: 100%;
  height: auto;
  margin: 0 auto;

  &__list {
    background: $white;
    display: flex;
    overflow-x: scroll;
    padding: $space 17px;
    border-bottom: 1px solid $grey-mid;

    &::after {
      content: "";
      width: 80px;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      background: rgb(255, 255, 255);
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 90%
      );
      pointer-events: none;
    }

    li {
      margin: 0;
      padding: $space/2 $space;
      text-align: center;
      white-space: nowrap;

      &:first-child {
        padding-left: 0;
      }

      &:hover {
        cursor: pointer;
      }

      a {
        color: $foxhills-black;
        padding-bottom: $space/2;
        text-transform: uppercase;
        font-family: $heading-font;
        letter-spacing: 1px;
      }

      &.nav-tabs__item--active,
      &.active {
        a {
          border-bottom: 1px solid $foxhills-black;
        }
      }
    }
  }

  .tab {
    display: none;
    height: auto;
    width: 100%;
    background: rgba(238, 238, 238, 0.8);
    backdrop-filter: blur(70px);

    &.active {
      display: block;
    }

    p,
    h2,
    h3 {
      color: $foxhills-black;
    }

    .nav-popup__buttons {
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      padding-bottom: $space;

      @include mq($from: M) {
        width: 100%;
        padding-bottom: $space * 2;
      }
    }

    .btn--primary {
      background: $primary-button-color;
      color: $secondary-button-color;

      &:hover {
        background: $secondary-button-color;
        color: $primary-button-color;
        border-color: $primary-button-color;
      }
    }
  }
}
