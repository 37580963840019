@import "../variables";
@import "../mixins";
@import "../mq";

.blog {
  &__list,
  &__post {
    @extend %constrain;
    padding-top: $space * 2;

    @include mq($from: L) {
      padding-top: $space * 4;
    }
  }

  &__list,
  &__feed {
    @include mq($from: S) {
      display: grid;
      gap: $space * 2;
      grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    }

    @include mq($from: L) {
      grid-template-columns: repeat(auto-fit, minmax(420px, 1fr));
      padding-bottom: $space * 2;
    }
  }

  &__post {
    border-bottom: 1px solid $border-color;
    margin: 0 auto;
    max-width: $maxContent;
    padding-bottom: $space * 2;
  }

  &__cat-tag-heading {
    @extend %constrain;
    padding-top: $space * 2;

    @include mq($from: L) {
      padding-top: $space * 4;
    }

    h1 {
      @include headingFont($h2-sm, $h2-lg);
      color: $primary-highlight-color;
      margin-bottom: 0;
    }

    strong {
      color: $secondary-highlight-color;
      text-transform: capitalize;
    }
  }

  &__article {
    position: relative;

    @include mq($until: S) {
      padding-bottom: $space * 2;
    }

    @include mq($from: M) {
      padding-bottom: $space * 2;
    }
  }

  &__image-container {
    background-color: $grey-lightest;
    display: block;
    height: 0;
    margin-bottom: $baseline;
    padding-top: 56.25%;
    position: relative;

    picture {
      display: block;
      max-width: 100%;
    }

    &::before {
      background: url("/img/dist/sprite/foxhills-logo-black.svg") center
        no-repeat $grey-lightest;
      background-size: 100%;
      content: "";
      display: block;
      filter: grayscale(1);
      height: 50px;
      left: 50%;
      opacity: 0.1;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 185px;
    }

    &--has-image {
      background: none;

      &::before {
        content: none;
      }
    }
  }

  &__image {
    @include object-fit(cover, center);
    display: block;
    height: 100%;
    left: 0;
    margin-bottom: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &__meta,
  &__categories,
  &__tags {
    display: flex;
  }

  &__date {
    display: block;
    margin-bottom: $baseline * 0.5;
    width: 100%;
  }

  &__list-date {
    background-color: $white;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    position: absolute;
    right: $space * 0.5;
    text-align: center;
    top: $space * 0.5;
    width: 2.5rem;

    @include mq($from: S) {
      right: $space;
      top: $space;
      width: 3rem;
    }

    span {
      display: block;
      letter-spacing: 3px;
      text-transform: uppercase;

      &.month {
        background-color: $primary-text-color;
        color: $white;
        font-size: 0.65rem;

        @include mq($from: S) {
          font-size: 0.778rem;
        }
      }

      &.day {
        font-size: 1.15rem;
        line-height: 1.5;

        @include mq($from: S) {
          font-size: 1.25rem;
        }
      }
    }
  }

  &__categories,
  &__tags {
    margin: 0;

    dt {
      font-weight: 500;
    }

    dd {
      margin: 0;
      padding-left: 0.25rem;

      &::after {
        content: "|";
        margin: 0 0 0 0.25rem;
      }

      &:last-child::after {
        content: none;
      }
    }

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__heading {
    @include headingFont($h3-sm, $h3-lg);
    margin-bottom: $baseline * 0.5;

    .blog__post & {
      @include headingFont($h2-sm, $h2-lg);
    }
  }

  &__excerpt {
    p:last-child {
      margin-bottom: 0;
    }

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__panels {
    min-height: $space * 2;

    @include mq($from: L) {
      min-height: $space * 4;
    }

    .constrain {
      max-width: $maxContent;
    }

    &:first-child {
      .panel:last-child {
        margin-bottom: -$space * 2;

        @include mq($from: L) {
          margin-bottom: -$space * 4;
        }
      }
    }
  }
}
