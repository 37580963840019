@import "../../variables";
// @import "../../mixins";
@import "../../mq";

.text-block {
  > *:last-child {
    margin-bottom: 0;
  }

  a:not(.btn) {
    @extend %link;

    &:after {
      display: none !important;
    }
  }

  .btn > a::after {
    content: none;
  }

  h1 {
    @include headingFont($h2-sm, $h2-lg);
  }
  ul {
    list-style-type: none;
    margin: 0 0 40px;
    padding: 0;

    li {
      margin-bottom: $space * 0.5;
      padding-left: 25px;
      position: relative;

      &::before {
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        background-color: $primary-highlight-color;
        position: absolute;
        top: 8px;
        left: 0;

        .panel--theme-medium &,
        .panel--theme-dark & {
          background-color: $white;
        }
      }

      ul,
      ol {
        padding-top: $space * 0.5;
      }
    }
  }

  ol {
    margin: 0 0 40px $space;
    padding: 0;

    li {
      margin-bottom: $space * 0.5;
      position: relative;

      ul,
      ol {
        padding-top: $space * 0.5;
      }
    }
  }

  .panel--theme-medium &,
  .panel--theme-dark & {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $white;
    }

    a:not(.btn) {
      color: $white;

      &::after {
        background-color: $white;
      }
    }

    .btn {
      color: $primary-button-color;

      &:hover {
        color: $secondary-button-color;
      }
    }
  }

  .panel--theme-medium & {
    .btn {
      color: $tertiary-highlight-color;
    }
  }

  .content-panel__row--two-column .content-panel__column & {
    @include mq($from: M) {
      padding: $space * 2;
    }

    @include mq($from: XXL) {
      padding: $space * 4;

      .panel--reduced-width & {
        padding: $space * 2;
      }
    }
  }

  .panel--halign-center .content-panel__row--two-column & {
    @include mq($from: M) {
      padding-left: $space * 2;
      padding-right: $space * 2;
    }
  }

  .panel--no-top-padding .content-panel__row--two-column & {
    @include mq($from: M) {
      padding-top: $space * 4;
    }
  }

  .panel--no-bottom-padding .content-panel__row--two-column & {
    @include mq($from: M) {
      padding-bottom: $space * 4;
    }
  }
}
