@import "../../variables";
@import "../../mixins";
@import "../../mq";

.content-panel {
  @extend %panel;

  &__heading {
    color: var(--theme-color);
    margin-bottom: $space;

    @include mq($from: L) {
      margin-bottom: $space * 2;
    }

    &:only-child {
      margin-bottom: 0;
    }

    .panel--theme-medium &,
    .panel--theme-dark & {
      color: $white;
    }
  }

  &__row {
    &--two-column {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      position: relative;
      padding: $space;

      &::before {
        border: 1px solid $border-color;
        content: "";
        display: block;
        height: 100%;
        left: 0;
        pointer-events: none;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 0;

        .panel--theme-medium & {
          border-color: $border-color-medium;
        }

        .panel--theme-dark & {
          border-color: $border-color-dark;
        }
      }

      @include mq($from: M) {
        flex-direction: row;
        margin: 0 -2%;
        padding: 0;
      }

      .content-panel__column {
        margin-bottom: $space;

        &:last-child {
          margin-bottom: 0;
        }

        @include mq($from: M) {
          width: 50%;
          margin-bottom: 0;
        }
      }
    }
  }

  &__column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    -ms-flex-negative: 0;

    > div {
      -ms-flex-negative: 0;
    }

    .panel--align-top & {
      justify-content: flex-start;
    }

    .panel--align-bottom & {
      justify-content: flex-end;
    }

    .panel--valign-top & {
      justify-content: flex-start;
    }

    .panel--valign-bottom & {
      justify-content: flex-end;
    }

    .panel--layout-wide-left & {
      @include mq($from: M) {
        width: 62.6666%;

        &:last-child {
          width: 29.3333%;
        }
      }
    }

    .panel--layout-wide-right & {
      @include mq($from: M) {
        width: 29.3333%;

        &:last-child {
          width: 62.6666%;
        }
      }
    }

    .panel--layout-extra-wide-left & {
      @include mq($from: M) {
        width: 71%;

        &:last-child {
          width: 21%;
        }
      }
    }

    .panel--layout-extra-wide-right & {
      @include mq($from: M) {
        width: 21%;

        &:last-child {
          width: 71%;
        }
      }
    }
  }
}
