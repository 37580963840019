@import "../variables";
// @import "../mixins";
@import "../mq";

.page--search {
  padding: $space * 2 0;

  @include mq($from: L) {
    padding: $space * 4 0;
  }
}

.search {
  &__inner {
    margin: 0 auto;
    max-width: $maxContent;
  }

  &__results-heading {
    @include headingFont($h2-sm, $h2-lg);
    border-bottom: 1px solid $border-color;
    margin-bottom: 0;
    padding-bottom: $space;

    @include mq($from: S) {
      padding-bottom: $space * 2;
    }
  }

  &__result {
    border-bottom: 1px solid $border-color;
    display: block;
    padding: $space * 1.5 0;

    @include mq($from: S) {
      padding: $space * 2 0;
    }

    > *:last-child {
      margin-bottom: 0;
    }

    &:hover {
      color: $hover-link-color;
    }
  }

  &__heading {
    @include headingFont($h3-sm, $h3-lg);
    transition: color $transition-speed $transition-timing;

    a:hover & {
      color: $hover-link-color;
    }
  }

  &__no-results {
    padding-top: $space * 2;

    p:last-child {
      margin-bottom: 0;
    }
  }
}
