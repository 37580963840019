@import "../../variables";
@import "../../mixins";
@import "../../mq";

.tabbed-content-panel {
  @extend %panel;

  &__heading {
    color: inherit;
    margin-bottom: $space * 2;
  }

  &__column {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .panel--align-top & {
      justify-content: flex-start;
    }

    .panel--align-bottom & {
      justify-content: flex-end;
    }

    .tabbed-content-panel__tab--two-column & {
      @include mq($from: M) {
        margin-right: $space * 2;
        width: calc(50% - #{$space});

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &__tab-area {
    height: 62px;
    position: relative;
  }

  &__current-tab {
    background: none !important;
    border: 0;
    height: 100%;
    left: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 70;

    &:focus {
      outline: none;
    }

    &:hover,
    &:focus {
      background: rgba($white, 0.25) !important;
    }

    @include mq($from: XXL) {
      display: none;
    }
  }

  &__tab-list {
    background: $body-background-color;
    display: flex;
    flex-direction: column;
    font-weight: 700;
    margin: 0 0 -1px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 50;

    &.open {
      box-shadow: 0 10px 64px 0 rgba(34, 45, 61, 0.13);
    }

    @include mq($from: XXL) {
      flex-direction: row;
      position: relative;

      &.open {
        box-shadow: none;
      }
    }
  }

  &__tab-list-item {
    border-bottom: 4px solid transparent;
    color: $grey-light;
    cursor: pointer;
    display: none;
    padding: 0 $space/4;

    @include mq($from: XXL) {
      display: block;

      &:first-child {
        padding-left: 0;
      }
    }

    &.active {
      border-bottom-color: $primary-highlight-color;
      color: var(--theme-color);
      display: block;
      order: -1;
      padding-right: $space;
      position: relative;

      @include mq($from: XXL) {
        order: initial;
        padding-right: $space/4;
      }

      &:after {
        border: solid $primary-highlight-color;
        border-width: 0 2px 2px 0;
        content: "";
        height: 10px;
        margin-top: -2px;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
        width: 10px;

        .tabbed-content-panel__tab-list.open & {
          margin-top: 2px;
          transform: translateY(-50%) rotate(-135deg);
        }

        @include mq($from: XXL) {
          display: none;
        }
      }
    }

    .open & {
      display: block;
    }

    &-icon {
      display: inline-block;
      margin: 0;
      max-height: 40px;
      max-width: 40px;
      vertical-align: middle;

      &--active {
        display: none;
      }

      .active & {
        &--active {
          display: inline-block;
        }

        &--disabled {
          display: none;
        }
      }
    }

    &-text {
      display: inline-block;
      padding: $space/2;
      vertical-align: middle;
    }
  }

  &__tabs {
    min-height: 320px;
  }

  &__tab {
    display: none;
    padding-top: $space;

    @include mq($from: M) {
      padding-top: $space * 2;
    }

    &.active {
      display: block;
    }

    &--two-column {
      flex-direction: column;
      flex-wrap: wrap;

      &.active {
        display: flex;
      }

      @include mq($from: M) {
        flex-direction: row;
      }
    }
  }
}
