.content-popup {
  &__overlay {
    background: rgba($foxhills-black, 0.45);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    left: 0;
    padding: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99999;
    transition: opacity 0.5s ease-in-out;
    opacity: 0;
    pointer-events: none;
    overflow: auto;

    .content-popup--visible & {
      animation: fadein 0.5s ease-in 0.5s normal 1 forwards;
      pointer-events: all;
    }
  }

  &__modal {
    max-height: 100vh;
    margin: 0 24px;

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 24px;
    }

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 24px;
    }
  }

  &__wrapper {
    max-width: 600px;
    padding: 10px;
    position: relative;
    background-color: $white;
  }

  &__inner {
    padding: 10px;
    border: 1px solid $foxhills-black;
  }

  &__content {
    padding: 20px;
    text-align: center;

    @include mq($from: XL) {
      padding: 20px 90px;
    }
  }

  &__close {
    position: relative;
    margin: -6px auto 0;
    padding: 6px 40px;
    display: block;
    font-weight: 600;
    text-transform: uppercase;
    background-color: $secondary-button-color;
    color: var(--theme-color);
    z-index: 1;

    &:hover {
      background-color: var(--theme-color);
      color: $secondary-button-color;

      svg {
        fill: $secondary-button-color;
      }
    }

    svg {
      width: 14px;
      height: 14px;
      vertical-align: middle;
      fill: var(--theme-color);
    }
    span {
      margin-left: 6px;
      vertical-align: middle;
    }
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
