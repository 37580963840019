.tripadvisor-embed {
  ul li {
    margin: 0;
  }

  img {
    margin-bottom: 0;
    max-height: 22px;
  }

  .cdsROW.wide {
    width: auto !important;

    &,
    .cdsROWContainer {
      font-family: inherit;
      height: auto;
    }

    .cdsROWContainer {
      align-items: center;
      display: flex;

      .cdsComponent {
        display: block;
      }

      .primary-footer & {
        @include mq($until: L) {
          justify-content: center;
        }
      }
    }

    .logo {
      padding: 0;
    }

    .cdsLocName {
      font-size: 0.875rem;
      max-width: none !important;
      padding-left: 0.75rem;
      padding-right: 0.75rem;
    }

    .cdsRating {
      font-size: 0.75rem;
      padding: 0;
    }
  }

  .cdsROW.cx_brand_refresh.wide .logo {
    background-color: transparent;
    border-right: 0;

    img {
      height: 22px;
      padding: 0;
      width: 104px;
    }
  }
}
