.sub-menu {
  border-bottom: 1px solid $border-color;
  padding: $space / 2 0;
  margin-bottom: $space;
  overflow-x: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;

  ul {
    display: flex;

    @include mq($from: XL) {
      display: flex;
    }

    li {
      display: block;
      flex: 1;

      @media all and (-ms-high-contrast: none) {
        border-right: 1px solid $border-color;
      }

      &:last-child {
        a {
          border-right: 0;
        }
      }

      a {
        display: block;
        border-right: 1px solid $border-color;
        text-align: center;
        padding: 10px $space * 1.5;
        font-family: $heading-font;
        text-transform: uppercase;
        font-size: 1rem;
        white-space: nowrap;

        @media all and (-ms-high-contrast: none) {
          white-space: normal;
          border-right: 0;
        }
      }
    }
  }
}

.sub-menu::-webkit-scrollbar {
  display: none;
}
