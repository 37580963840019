@import "../variables";
@import "../mixins";
// @import "../mq";

%field {
  background: $white;
  border: 1px solid $form-border-color;
  border-radius: $border-radius * 0.5;
  box-sizing: border-box;
  height: 48px;
  margin: 0;
  max-width: 100% !important; // 100% rather than unset for IE11
  outline: none;
  padding: 0 12px;
  transition: border-color $transition-speed $transition-timing,
    background-color $transition-speed $transition-timing;
  width: 100%;

  &:focus {
    border-color: $primary-link-color;
    outline: none;

    .panel--theme-medium & {
      border-color: $secondary-highlight-color;
    }
  }

  &:not(select):read-only,
  &:not(select):-moz-read-only {
    background: $form-border-color;
    cursor: default;
  }
}

%form {
  padding: 0;

  fieldset {
    margin: 0 !important;
    padding: 0 !important;
  }

  .umbraco-forms-indicator,
  .indicator {
    color: $red;
  }

  form {
    legend {
      left: -9999px;
      position: absolute;
    }

    h2,
    h3,
    h4 {
      .panel--theme-medium &,
      .panel--theme-dark & {
        color: $white;
      }
    }

    .umbraco-forms-field,
    .field {
      @include clear;
      margin-bottom: 24px;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    .umbraco-forms-field-wrapper,
    .field-wrapper {
      position: relative;
      margin-top: ($space * 0.5);
      width: 100%;
    }

    .umbraco-forms-label,
    .umbraco-forms-tooltip,
    .label,
    .tooltip {
      color: $form-label-color;
      font-size: 0.875rem;
    }

    .umbraco-forms-label,
    .label {
      text-align: left;
    }

    .umbraco-forms-tooltip,
    .tooltip {
      text-align: right;
    }

    .multiplechoice,
    .singlechoice {
      .umbraco-forms-label,
      .label {
        @include clear;
        margin-bottom: ($space * 0.5);
      }
    }

    .note,
    .help-block {
      display: block;
      line-height: normal;
      margin-bottom: 8px;
    }

    .checkbox__psuedo-elements,
    .dataconsent label {
      display: inline-block;
      font-size: 0.875rem;
    }
  }

  .radio-container {
    display: block;
    position: relative;
    padding-left: 40px;
    padding-top: 5px;
    cursor: pointer;
    color: $form-label-color;
    user-select: none;
    margin-bottom: $space;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 25px;
      width: 25px;
      background-color: $white;
      border: 1px solid $form-border-color;
    }

    &:hover input + .checkmark {
      border: 1px solid $primary-highlight-color;
    }

    input:checked + .checkmark {
      background-color: $primary-highlight-color;
      border: 1px solid $primary-highlight-color;
    }

    .checkmark::after {
      content: "";
      position: absolute;
      display: none;
      left: 9px;
      top: 5px;
      width: 5px;
      height: 10px;
      border: solid $white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    input:checked + .checkmark:after {
      display: block;
    }
  }

  .dataconsent {
    padding: 0;

    .umbraco-forms-field-wrapper,
    .field-wrapper {
      align-items: center;
      display: flex;
    }

    input[type="checkbox"] {
      display: inline-block;
      margin-bottom: 5px;
      margin-right: 1rem;
    }
  }

  span.checkboxlist {
    padding: 0 $space 0 0;
  }

  .radiobuttonlist,
  .checkboxlist {
    input[type="checkbox"],
    input[type="radio"] {
      left: -100%;
      opacity: 0;
      position: absolute;
      top: 0;

      &:checked + label::after,
      &:checked + input[type="hidden"] + label::after {
        display: block;
      }

      &:focus + label::before {
        border-color: $primary-highlight-color;

        .panel--theme-medium & {
          border-color: $secondary-highlight-color;
        }
      }
    }

    label {
      display: inline-block;
      padding: 1px 0 2px 2rem;
      margin-bottom: 8px;
      position: relative;

      &::before {
        background-color: $white;
        content: "";
        display: block;
        width: 24px;
        height: 24px;
        position: absolute;
        top: 0;
        left: 0;
        border: 1px solid $form-border-color;
      }

      &::after {
        content: "";
        display: none;
        width: 14px;
        height: 14px;
        position: absolute;
        top: 5px;
        left: 5px;
        background-color: $primary-highlight-color;
      }
    }
  }

  .radiobuttonlist label {
    &::before,
    &::after {
      border-radius: 100%;
    }
  }

  label,
  .umbraco-forms-label {
    display: block;
    font-weight: normal;
    color: $form-label-color;
    line-height: normal;
    margin-bottom: 4px;

    .panel--theme-medium &,
    .panel--theme-dark & {
      color: $form-label-color-dark;
    }
  }

  input[type="text"],
  input[type="search"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="week"],
  input[type="month"],
  input[type="time"],
  input[type="number"],
  input[type="password"],
  input[type="tel"],
  input[type="email"],
  input[type="url"],
  input[type="color"],
  input[type="file"],
  textarea {
    @extend %field;
  }

  input[type="file"] {
    background-color: $white !important;
    padding-bottom: 0;
    padding-top: 9px;
  }

  textarea {
    padding: 12px;
  }

  select {
    @extend %field;
    background-repeat: no-repeat;
    background-position: right 0.6rem center;
    background-image: url("/img/dist/sprite/select-arrow.svg");
    line-height: normal;
    padding-bottom: 0;
    padding-right: 30px;
    padding-top: 0;

    &::-ms-expand {
      display: none;
    }
  }

  .umbraco-forms-form input.text,
  .umbraco-forms-form textarea {
    max-width: 100% !important;
    padding: 10px 12px;
    width: 100%;
  }
  // Recaptcha fixes (v2)
  .recaptchatable input[type="text"] {
    line-height: 18px !important;
  }

  #recaptcha_area,
  #recaptcha_table {
    table-layout: auto !important;
  }

  .recaptcha_is_showing_audio embed {
    height: 0;
    width: 0;
  }

  #recaptcha_area input[type="text"] {
    display: inline-block !important;
    height: auto !important;
  }

  #recaptcha_table td {
    line-height: normal;
  }
  // Recaptcha fixes (v3)
  .grecaptcha-badge {
    z-index: 9999 !important;
  }
  // Generic validation
  span.field-validation-error,
  input.input-validation-error,
  textarea.input-validation-error {
    border-color: $error;
  }

  span.field-validation-error {
    color: $error !important;
    display: inline-block;
    font-size: 0.81818rem;
    margin: 0;
    padding: 3px 0 0;
    position: relative;
  }

  .validation-summary-errors {
    color: $error;
    font-weight: bold;
    margin-bottom: $space;
    text-transform: uppercase;
  }

  input.input-validation-error,
  textarea.input-validation-error {
    border: 1px solid $error;
  }

  .umbraco-forms-navigation,
  .form-actions {
    padding: $baseline * 0.5 0 0;
  }
}

// Placeholders
::-webkit-input-placeholder {
  color: $form-placeholder-color;
}

:-ms-input-placeholder {
  color: $form-placeholder-color;
}

::placeholder {
  color: $form-placeholder-color;
}

// Umbraco Forms
div.umbraco-forms-form {
  @extend %form;
}

// Datepickers
.pika-single {
  border-color: $form-border-color;
  border-radius: $border-radius * 0.5;

  button {
    border-radius: 0;
    min-height: 0;
    text-align: center;
  }

  td {
    border: 1px solid $white;
  }

  .pika-button {
    color: $primary-text-color;
    padding: 8px;

    &:hover {
      background-color: $primary-text-color;
    }
  }

  .is-today .pika-button {
    color: $primary-highlight-color;

    &:hover {
      color: $secondary-highlight-color;
    }
  }

  .pika-lendar {
    margin: 10px;
  }

  .pika-title {
    align-items: center;
    color: $primary-text-color;
    display: flex;
    padding-bottom: 10px;

    select {
      border: 0;
      color: inherit;
      height: 1rem;
      top: 0;
    }
  }

  .pika-label {
    color: $primary-text-color;
    float: none;
    font-family: $base-font;
    height: 1rem;
    order: 3;
    padding: 0 3px;

    &:first-of-type {
      order: 2;
    }
  }

  .pika-prev,
  .pika-next {
    background: url("/img/dist/sprite/arrow.svg") no-repeat left center;
    float: none;
    height: $space;
    opacity: 0.25;
  }

  .pika-prev {
    left: 3px;
    margin-right: auto;
    order: 1;
    transform: rotate(180deg);
  }

  .pika-next {
    margin-left: auto;
    order: 4;
    right: 3px;
  }

  .pika-select {
    padding: 0;
  }

  .pika-table {
    font-family: $base-font;
    margin: 0;

    abbr {
      text-decoration: none;
    }
  }
}
