@import "../variables";
//@import "../mixins";
@import "../mq";

//// Footer
.primary-footer {
  background: $footer-background;
  padding: 0 0 $space;

  &__top {
    border-bottom: 1px solid $border-color;
    margin-bottom: $space * 2;
    padding-bottom: $space * 2;
    text-align: center;
    width: 100%;

    @include mq($from: L) {
      margin-bottom: $space * 4;
      padding-bottom: $space * 4;
    }

    img {
      margin-bottom: 0;
      max-height: $space * 6;
    }
  }

  &__large-title {
    font-size: 1.5rem;
    line-height: 2rem;

    @include mq($from: XL) {
      font-size: 2rem;
      line-height: 3rem;
    }
  }

  &__small-title {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;

    @include mq($from: L) {
      flex-direction: row;
    }
  }

  &__number {
    display: block;
    margin-bottom: $space;
  }

  &__col {
    font-size: 1.125rem;
    line-height: 1.5rem;
    text-align: center;
    margin-bottom: $space * 2;

    @include mq($from: L) {
      width: 25%;
      text-align: left;
      padding-right: 5%;
      margin-bottom: 0;
    }

    @include mq($from: XL) {
      width: 20%;
    }

    &:nth-child(5) {
      margin-top: $space * 2;

      @include mq($from: L) {
        margin-top: 0;
        width: 25%;
        padding-right: 0;
      }

      @include mq($from: XL) {
        width: 35%;
      }
    }

    p {
      font-size: 0.875rem;
      line-height: 1.2rem;
    }

    nav {
      text-transform: uppercase;

      ul {
        display: flex;
        justify-content: center;

        @include mq($from: L) {
          display: inline-block;
        }
        li {
          margin: 0 $space $space * 2;
          font-size: 0.875rem;

          @include mq($from: L) {
            margin: 0 0 $space;
            font-size: 1rem;
          }

          svg {
            width: 24px;
            height: 24px;
            margin-top: -5px;
            margin-right: 8px;
          }
        }
      }
    }
  }

  &__logo-image {
    width: 90px;
    height: 24px;
    margin-bottom: $space * 1.5;

    .theme--farleigh & {
      height: auto;
    }

    @include mq($from: L) {
      width: 150px;
      height: 36px;
      margin-bottom: $space * 1.5;
    }
  }

  &__bottom {
    margin: $space * 5 0;
    width: 100%;
    text-align: center;

    @include mq($from: L) {
      display: flex;
      justify-content: space-between;
      text-align: left;
    }

    p {
      color: $footer-text-color;
      font-size: 1rem;
      margin-bottom: $space * 2;

      @include mq($from: L) {
        margin-bottom: 0;
      }
    }
  }

  &__nav {
    margin-bottom: $space;

    @include mq($from: L) {
      margin-bottom: 0;
    }

    ul {
      display: flex;
      justify-content: center;
      flex-direction: column;

      li {
        margin: 0 $space/2 $space;

        a {
          color: var(--theme-color);
          text-transform: uppercase;

          &:hover {
            color: $grey-medium;
          }

          &:active {
            color: $grey-medium;
          }

          &:focus {
            color: $grey-dark;
          }
        }
      }
    }
  }

  &__awards-list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    @include mq($from: L) {
      flex-wrap: nowrap;
    }
  }
  &__awards-item {
    margin: 8px;
  }
  &__awards-image {
    display: block;
    width: 62px;
    max-width: 120px;
    max-height: unset !important;
    margin: 0;

    @include mq($from: L) {
      width: 100%;
    }
  }

  .btn {
    background: $white;
    color: var(--theme-color);
    border-color: var(--theme-color);
    border-radius: 66px;

    @include mq($from: XL) {
      padding: $space $space * 5;
    }

    &:hover {
      background: $grey-medium;
      border-color: $grey-medium;
      color: $white;
    }

    &:active {
      background: $grey-dark;
      border-color: $grey-dark;
      color: $white;
    }

    &:focus {
      background: var(--theme-color);
      border-color: var(--theme-color);
      color: $white;
    }
  }
}
