.nav-popup {
  backdrop-filter: blur(70px);
  background-color: rgba(238, 238, 238, 0.95);
  bottom: 0;
  color: $foxhills-black;
  display: block;
  height: 0;
  left: 0;
  overflow: hidden;
  padding: 0;
  pointer-events: none;
  position: relative;
  right: 0;
  top: 0;
  transition: 0.5s;
  width: 100%;
  z-index: 200;

  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    background-color: rgba(238, 238, 238, 0.8);
    -webkit-backdrop-filter: blur(70px);
    backdrop-filter: blur(70px);
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    backdrop-filter: initial;
    background: rgba(238, 238, 238, 1);
  }

  @include mq($from: XL) {
    height: 652px;
    position: absolute;
    top: 77px;
    opacity: 0;
  }

  &--slide-down {
    @include mq($from: XL) {
      height: 0;
      position: relative;
      top: 0;
    }
  }

  &.open {
    pointer-events: all;
    height: 100%;

    @include mq($from: XL) {
      opacity: 1;
      height: 652px;
    }
  }

  &__close {
    position: absolute;
    top: $space * 1.5;
    right: $space;
    z-index: 300;

    @include mq($from: XL) {
      position: absolute;
      top: 3%;
      right: 0;
      height: 94%;
      border-left: 1px solid rgba(13, 6, 11, 0.3);
      padding: 0 $space;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &:hover {
      svg {
        opacity: 0.5;
      }
    }

    svg {
      width: 25px;
      height: 25px;
      fill: var(--theme-color);
      transition: $transition-speed;
    }
  }

  &__content {
    padding: 70px 35px 40px;

    @include mq($from: XL) {
      padding: 50px 50px 40px;
      display: flex;
      justify-content: space-between;
    }

    @include mq($from: XXXL) {
      padding-top: 40px;
    }
  }

  &__cols {
    @include mq($from: M) {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-between;
    }

    @include mq($from: XL) {
      width: 50%;
    }

    @include mq($from: XXXL) {
      width: 55%;
    }

    h2 {
      color: $foxhills-black;
      font-size: 1.875rem;
      line-height: 2.2rem;
    }
  }

  &__image {
    height: 250px;
    margin-bottom: $space * 1.5;

    @include mq($from: XL) {
      height: 200px;
    }

    @include mq($from: XXXL) {
      height: 200px;
    }

    img {
      @include object-fit(cover, center);
      display: block;
      height: 100%;
      margin: 0;
      padding: 0;
      transition: $transition-speed;
      width: 100%;
    }
  }

  &__read-more {
    color: var(--theme-color);
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.8125rem;
    border-bottom: 1px solid $grey-light;
    padding-bottom: 4px;
    display: inline-block;

    @include mq($from: L) {
      color: var(--theme-color);
      border-bottom: 1px solid var(--theme-color);
    }
  }

  &__col {
    margin-bottom: $space * 2;

    @include mq($from: M) {
      width: 48%;
    }

    @include mq($from: XL) {
      width: 48%;
      padding: $space * 1.5 0 0;
      margin-bottom: 0;
    }

    @include mq($from: XXXL) {
      width: 45%;
    }

    h2,
    h3 {
      @include mq($from: XL) {
        font-size: 1.5rem;
      }

      @include mq($from: XXL) {
        font-size: 2rem;
      }
    }

    p {
      @include mq($from: XL) {
        font-size: 0.875rem;
        line-height: 1.25rem;
      }

      @include mq($from: XXL) {
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }
  }

  &__menus {
    @include mq($from: XL) {
      width: 45%;
      padding-right: 5%;
    }

    @include mq($from: XXXL) {
      width: 40%;
      padding-right: 5%;
    }

    &.hide {
      display: none;
    }
  }

  &__nav {
    ul {
      @include mq($from: M) {
        column-count: 2;
        column-gap: $space;
      }

      li {
        &:first-of-type {
          a {
            font-weight: bold;
          }
        }

        a {
          color: var(--theme-color);
          border-bottom: 1px solid rgba(0, 0, 0, 0.2);
          display: block;
          font-size: 1.25rem;
          font-family: $heading-font;
          padding: $space $space * 1.5 $space $space/2;
          background-image: url(/img/dist/sprite/arrow-right-black.svg);
          background-repeat: no-repeat;
          background-position: right 10px top 23px;
          background-size: 10px;
          transition: $transition-speed;

          .theme--farleigh & {
            background-image: url(/img/dist/sprite/arrow-right-blue.svg);
          }

          @include mq($from: M) {
            -webkit-column-break-inside: avoid;
            page-break-inside: avoid;
            break-inside: avoid;
          }

          @include mq($from: XL) {
            font-size: 1rem;
            padding: $space $space * 1.5 $space $space/2;
            background-position: right 15px top 23px;
          }

          @include mq($from: XXL) {
            font-size: 1.25rem;
          }

          &:hover {
            background: var(--theme-color);
            color: $white;
            background-image: url(/img/dist/sprite/arrow-right-white.svg);
            background-repeat: no-repeat;
            background-position: right 15px top 23px;
            background-size: 10px;

            @include mq($from: XL) {
              background-position: right 10px top 23px;
            }
          }

          &:active,
          &:focus {
            background: $grey-dark;
            color: $white;
            background-image: url(/img/dist/sprite/arrow-right-white.svg);
            background-repeat: no-repeat;
            background-position: right 15px top 23px;
            background-size: 10px;

            @include mq($from: XL) {
              background-position: right 10px top 23px;
            }
          }
        }
      }
    }
  }

  &__buttons {
    margin-top: $space * 2;
    .btn {
      margin-right: $space;
      padding-left: $space * 2;
      padding-right: $space * 2;
      margin-bottom: $space;

      @include mq($from: M) {
        margin-bottom: 0;
        padding-left: $space * 3;
        padding-right: $space * 3;
      }

      @include mq($from: XL) {
        margin-bottom: $space;
        padding: $space/2 $space * 2;
      }

      @include mq($from: XXXL) {
        margin-bottom: $space;
        padding-left: $space * 3;
        padding-right: $space * 3;
      }
    }
  }

  &__more {
    color: $black;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    display: block;
    margin-top: $space;

    &:before {
      content: "More in this section";
    }

    &.open {
      &:before {
        display: none;
      }
      &:after {
        content: "Close section";
      }

      svg {
        transform: rotate(-180deg);
      }
    }

    &:hover,
    &:active,
    &:focus {
      color: $black;
      text-decoration: underline;
    }

    svg {
      display: block;
      fill: $black;
      height: 27px;
      margin: 0 auto;
      width: 27px;
    }
  }
}
