.nav-buttons {
  display: flex;

  &__icon {
    fill: $primary-link-color;
    height: $space;
    transition: fill $transition-speed $transition-timing;
    width: $space;

    a:hover & {
      fill: $hover-link-color;
    }
  }

  &__li {
    display: none;
    position: relative;

    @include mq($from: 1180px) {
      display: inline-block;

      &:first-child a {
        margin-left: 0;
      }
    }

    a {
      border-radius: 66px;
      border: 1px solid $button-border-color;
      display: inline-block;
      font-size: 1rem;
      font-weight: 600;
      line-height: normal;
      margin-left: 9px;
      padding: 9px 12px;
      text-transform: uppercase;
      transition: all $transition-speed $transition-timing;
      position: relative;
      z-index: 2;

      @include mq($from: XXL) {
        padding: 9px 14px;
      }
      @include mq($from: XXXL) {
        padding: 9px 20px;
      }

      &:hover {
        border-color: $hover-link-color;
      }
    }

    // Contact button that appears on all screen sizes
    &--circle {
      display: inline-block;

      @include mq($from: XXXL) {
        margin-left: $space;
      }

      a {
        height: $space * 2;
        padding: 8px 10px;
        width: $space * 2;
      }
    }

    // Small icons that appear on smaller screen sizes
    &--icon {
      display: inline-block;
      margin-left: 0;

      @include mq($from: 1180px) {
        display: none;
      }

      a {
        height: $space * 2;
        padding: 8px 10px;
        width: $space * 2;
      }
    }

    // Dropdown hover for the Book button only!
    &--with-children {
      &:hover,
      &:active,
      &:focus {
        a {
          border-top-left-radius: 20px;
          border-top-right-radius: 20px;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          border-color: $black;
          border-bottom: 1px solid $white;
        }

        .nav-buttons__dropdown__li {
          a {
            border: 0;
            border-radius: 66px;
          }
        }

        .nav-buttons__dropdown {
          display: block;
          pointer-events: all;
          opacity: 1;
          transition: 0.5s;
          transition-delay: 0.2s;
        }
      }
    }
  }

  // Dropdown (for the Book button only! This has specific widths attached)
  &__dropdown {
    position: absolute;
    border: 1px solid $black;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    background: $white;
    left: 0;
    top: 52px;
    z-index: 1;
    opacity: 0;
    pointer-events: none;
    transition: 0;
    padding: 10px 0;
    transition-delay: 0;
    min-width: 300px; // Required for both drop down menus (button & icon)

    @media all and (-ms-high-contrast: none) {
      min-width: 340px;
    }

    &:before {
      content: "";
      display: block;
      background: $white;
      position: absolute;
      top: -16px;
      height: 16px;
      width: 88px;
      border-left: 1px solid $black;
      border-right: 1px solid $black;
      left: -1px;
      z-index: 2;

      @include mq($from: 1180px) {
        width: 72px;
      }
      @include mq($from: XXL) {
        width: 76px;
      }
      @include mq($from: XXXL) {
        width: 88px;
      }

      @media all and (-ms-high-contrast: none) {
        width: 90px;
        height: 17px;

        @include mq($from: 1180px) {
          width: 74px;
        }
        @include mq($from: XXL) {
          width: 78px;
        }
        @include mq($from: XXXL) {
          width: 90px;
        }
      }
    }

    &__li {
      display: block;
      padding-right: 20px;
      margin-bottom: 5px;

      @include mq($from: 1180px) {
        padding: 0 7px;
      }
      @include mq($from: XXXL) {
        padding: 0 9px;
      }

      &:hover,
      &:active,
      &:focus {
        background: transparent;

        a {
          border: 0;
          border-radius: 66px;
        }
      }

      a {
        padding: 10px 35px 10px 10px;
        border: 0;
        white-space: nowrap;
        width: 100%;
        border-radius: 66px;
        background-image: url(/img/dist/sprite/arrow-right-black.svg);
        background-repeat: no-repeat;
        background-position: right 15px center;
        background-size: 7px;

        .theme--farleigh & {
          background-image: url(/img/dist/sprite/arrow-right-blue.svg);
        }

        @include mq($from: XXXL) {
          padding-left: 15px;
        }

        &:hover,
        &:active,
        &:focus {
          border: 0;
          background: var(--theme-color);
          border-radius: 66px;
          background-image: url(/img/dist/sprite/arrow-right-white.svg);
          background-repeat: no-repeat;
          background-position: right 15px center;
          background-size: 7px;
          color: $white;
        }

        // Subtitle of the dropdown menu item i.e. Stay, Golf, etc
        span {
          font-weight: 300;
          display: inline-block;
          margin-right: 15px;
          min-width: 50px;
        }

        // Arrow icon
        svg {
          width: 6px;
          height: 12px;
          margin-top: -4px;
          margin-left: 10px;
          fill: $white;
        }
      }
    }

    // Smaller width background connector for the icon menu
    &--small {
      &:before {
        width: 40px;
      }
    }
  }
}
