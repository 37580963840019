@import "../../variables";
@import "../../mixins";
@import "../../mq";

.image-block {
  .content-panel__row--two-column & {
    @include mq($from: M) {
      height: 100%;
    }
  }

  &__image {
    display: block;
    margin-bottom: 0;
    min-width: 100%;

    .content-panel__row--two-column & {
      @include mq($from: M) {
        @include object-fit(cover, center);
        height: 100%;
        width: 100%;
      }
    }
  }
}
