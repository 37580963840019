.embed-block {
  position: relative;
  // padding-bottom: 56.25%; /* 16:9 */
  // height: 0;

  // iframe {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  // }

  &__link-container {
    padding-bottom: $space;
    text-transform: uppercase;

    @include mq($from: L) {
      padding-bottom: $space * 2;
    }

    .content-panel--has-heading & {
      margin-top: -$space;
    }
  }

  &__link {
    align-items: center;
    display: inline-flex;

    &[href*="facebook.com"],
    &[href*="instagram.com"],
    &[href*="linkedin.com"],
    &[href*="twitter.com"],
    &[href*="youtube.com"] {
      &::before {
        background-color: $primary-link-color;
        content: "";
        display: block;
        margin-right: 0.75rem;
        mask-repeat: no-repeat;
        mask-size: 100%;
        width: 24px;
      }
    }

    &[href*="facebook.com"]::before {
      mask-image: url("/img/dist/sprite/facebook.svg");
      height: 24px;
      width: 12px;
    }

    &[href*="instagram.com"]::before {
      mask-image: url("/img/dist/sprite/instagram.svg");
      height: 24px;
    }

    &[href*="linkedin.com"]::before {
      mask-image: url("/img/dist/sprite/linkedin.svg");
      height: 32px;
    }

    &[href*="twitter.com"]::before {
      mask-image: url("/img/dist/sprite/twitter.svg");
      height: 22px;
    }

    &[href*="youtube.com"]::before {
      mask-image: url("/img/dist/sprite/youtube.svg");
      height: 18px;
    }
  }
}

// Additional styles for Flockler embeds
.flockler-carousel_v2-item {
  padding: 0 5px;

  &__wrapper {
    border-radius: 0;
  }

  &--instagram {
    .flockler-carousel_v2-item__content
      figure.flockler-carousel_v2-item__context {
      background: transparent;
      box-shadow: none;
      color: $white;
      padding: 0;
      transition: opacity $transition-speed $transition-timing;

      &:hover {
        opacity: $opacity;
      }
    }
  }

  &__text__wrapper {
    padding: 2.5rem 1rem;
  }
}

div.flockler-carousel_v2-item__text__content p {
  font-size: 0.875rem;
  line-height: 1.5;
}
