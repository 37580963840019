@import "../variables";
// @import "../mixins";
@import "../mq";

.menu-toggle {
  height: 45px;
  margin: 0 auto;
  position: absolute;
  right: $space;
  transition: all $transition-speed $transition-timing;
  width: 55px;
  z-index: 11;

  &::before {
    background-color: rgba(0, 0, 0, 0.4);
    content: "";
    height: 100%;
    left: 0;
    position: fixed;
    opacity: 0;
    top: 0;
    transition: all $transition-speed $transition-timing;
    width: 100%;
    visibility: hidden;
    z-index: 10;
  }

  @include mq($from: L) {
    display: none;
  }

  &__inner {
    background-color: $primary-highlight-color;
    border-radius: 4px;
    height: 100%;
    position: relative;
    width: 100%;
    z-index: 12;
  }

  &__meat {
    background-color: $white;
    border-radius: 3px;
    height: 3px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(0deg);
    transition: all $transition-speed $transition-timing;
    backface-visibility: hidden;
    width: 65%;

    &::before,
    &::after {
      background-color: $body-background-color;
      border-radius: 3px;
      content: "";
      height: 3px;
      position: absolute;
      transition: all $transition-speed $transition-timing;
      backface-visibility: hidden;
      width: 100%;
    }

    &::before {
      top: -9px;
    }

    &::after {
      top: 9px;
    }
  }

  &.active {
    .menu-toggle__inner {
      //color: $color-primary;
      background: $primary-highlight-color;
      border-radius: 4px 0 0 4px;
    }

    &::before {
      opacity: 1;
      visibility: visible;
    }

    .menu-toggle__meat {
      background-color: $primary-highlight-color;
      transform: translateX(-50%) translateY(-50%) rotate(180deg);

      &::before {
        transform: translateX(0px) translateY(9px) rotate(-40deg);
        width: 100%;
      }

      &::after {
        transform: translateX(0px) translateY(-9px) rotate(40deg);
        width: 100%;
      }
    }
  }
}
