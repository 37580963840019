@font-face {
  font-family: "Century Gothic";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../../fonts/century-gothic-regular.woff2") format("woff2"),
    url("../../fonts/century-gothic-regular.woff") format("woff");
}

@font-face {
  font-family: "Century Gothic";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("../../fonts/century-gothic-regular-italic.woff2") format("woff2"),
    url("../../fonts/century-gothic-regular-italic.woff") format("woff");
}

@font-face {
  font-family: "Century Gothic";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../../fonts/century-gothic-bold.woff2") format("woff2"),
    url("../../fonts/century-gothic-bold.woff") format("woff");
}

@font-face {
  font-family: "Century Gothic";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("../../fonts/century-gothic-bold-italic.woff2") format("woff2"),
    url("../../fonts/century-gothic-bold-italic.woff") format("woff");
}

@font-face {
  font-family: "Libre Baskerville";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../../fonts/libre-baskerville-regular.woff2") format("woff2"),
    url("../../fonts/libre-baskerville-regular.woff") format("woff");
}
