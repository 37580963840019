.mobile-header {
  background-color: $white;
  box-shadow: $box-shadow;
  position: fixed;
  top: 0;
  transition: all $transition-speed $transition-timing;
  width: 100%;
  z-index: 9999;
  height: $mobile-header-height;
  padding: 10px 0;

  @include mq($from: mobile-menu) {
    display: none;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $border-color;
    padding: 0 10px 9px;
    position: relative;
    z-index: 20;

    li {
      flex: 1;
      margin: 0 6px;
    }

    a {
      border: 1px solid $foxhills-black;
      padding: 3px 24px;
      border-radius: 66px;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 0.875rem;
      display: block;
      text-align: center;
      white-space: nowrap;
      transition: $transition-speed;

      &:hover {
        background: $foxhills-black;
        color: $white;
      }
    }

    &__book {
      &.open {
        background-color: $grey-dark;
        color: $white;
      }
    }
  }

  &__bottom {
    display: flex;
    padding: 4px 16px;
    justify-content: space-between;
  }

  &__logo {
    padding-top: 15px;
    width: 120px;
    height: 35px;

    svg,
    img {
      width: 120px;
      height: 35px;

      .theme--farleigh & {
        transform: translateY(-4px);
        width: auto;
      }
    }
  }

  &__fox {
    fill: var(--theme-color);
  }

  &__call {
    margin-right: 24px;
    padding-right: 24px;
    padding-top: 10px;
    position: relative;

    &:after {
      content: "";
      background: $border-color;
      position: absolute;
      right: 0;
      height: 32px;
      width: 1px;
      top: 14px;
    }

    a {
      width: 36px;
      height: 36px;
      padding: 4px 7px;
      border: 1px solid $black;
      border-radius: 100%;
      display: block;
    }
  }

  &__icon {
    height: 60px;
    flex: 1;
    text-align: center;
    svg {
      width: 40px;
      height: 60px;
    }
  }

  &__hamburger {
    padding-top: 12px;
  }

  &__sub-menu {
    position: absolute;
    top: 41px;
    left: 0;
    background: $white;
    width: 100%;
    padding: 10px 10px;
    z-index: 100;
    border-top: 1px solid $grey-light;
    border-bottom: 1px solid $grey-light;
    opacity: 0;
    pointer-events: none;
    transition: $transition-speed;

    &.open {
      opacity: 1;
      pointer-events: all;
    }

    li {
      padding: 0;
      margin-bottom: 5px;

      a {
        padding: 10px 25px 10px 15px;
        border: 0;
        border-radius: 66px;
        white-space: nowrap;
        text-align: left;
        background-image: url(/img/dist/sprite/arrow-right-black.svg);
        background-repeat: no-repeat;
        background-position: right 20px center;
        background-size: 7px;

        &:hover,
        &:active,
        &:focus {
          border: 0;
          background-image: url(/img/dist/sprite/arrow-right-white.svg);
          background-repeat: no-repeat;
          background-position: right 10px center;
          background-size: 7px;
          color: $white;
        }

        &:focus {
          background-color: $grey-dark;
        }

        // Subtitle of the dropdown menu item i.e. Stay, Golf, etc
        span {
          font-weight: 300;
          display: inline-block;
          margin-right: 15px;
          min-width: 50px;
        }

        // Arrow icon
        svg {
          width: 7px;
          height: 14px;
          margin-top: 6px;
          margin-left: 10px;
          fill: $white;
          float: right;
        }
      }
    }
  }

  &__mask {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 51px;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 10;
    pointer-events: none;
    opacity: 0;
    transition: $transition-speed;

    &.open {
      opacity: 1;
      pointer-events: all;
    }
  }
}
