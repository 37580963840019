.top-navigation {
  width: 100%;
  display: flex;
  justify-content: space-between;

  ul {
    display: flex;

    li {
      margin-right: 36px;
      text-transform: uppercase;
      font-size: 0.875rem;
    }
  }

  &__wrapper {
    border-bottom: 1px solid $border-color;
    padding: 17px 0;
  }
}
