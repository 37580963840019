.membership {
  &__panels {
    .constrain {
      max-width: $maxContent;
    }
  }

  &__form-container {
    @extend %constrain;
    max-width: $maxContent;
    padding-bottom: $space * 2;

    @include mq($from: L) {
      padding-bottom: $space * 4;
    }

    &:first-child {
      padding-top: $space * 2;

      @include mq($from: L) {
        padding-top: $space * 4;
      }
    }
  }

  &__form {
    @extend %form;

    form .label {
      width: auto;
    }
  }
}
