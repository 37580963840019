@import "../variables";
//@import "../mixins";
@import "../mq";

// Social icons
.social-share {
  &__list {
    display: inline-block;
    margin-bottom: $space * 3;

    @include mq($from: L) {
      display: flex;
    }
  }

  &__item {
    margin-right: 12px;
    display: inline-block;

    a {
      align-items: center;
      justify-content: center;
      display: flex;
      background: var(--theme-color);
      height: $space * 2;
      width: $space * 2;
      border-radius: 100%;
      transition: $transition-speed;

      &:hover {
        background: $grey-medium;
      }

      &:active {
        background: $grey-medium;
      }

      &:focus {
        background: $grey-dark;
      }
    }
  }

  &__icon {
    position: relative;
    transition: stroke $transition-speed $transition-timing;
    fill: $white;
    width: 100%;

    &--facebook {
      height: 25px;
    }

    &--twitter {
      height: 20px;
    }

    &--instagram {
      height: 20px;
    }

    &--linkedin {
      height: 22px;
    }

    &--youtube {
      height: 22px;
      top: 1px;
    }

    &--tiktok {
      height: 20px;
    }
  }
}
