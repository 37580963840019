.main-nav {
  width: 45%;
  display: flex;
  justify-content: flex-end;

  &__divider {
    border-left: 1px solid $border-color;
    padding-left: 24px;
    margin-left: 24px;

    @include mq($from: 1180px) {
      padding-left: 12px;
      margin-left: 12px;
    }
    @include mq($from: XXL) {
      padding-left: 20px;
      margin-left: 20px;
    }
    @include mq($from: XXXL) {
      padding-left: 24px;
      margin-left: 24px;
    }
  }
}
