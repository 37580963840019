@import "../variables";
// @import "../mixins";
// @import "../mq";

.breadcrumbs {
  border-bottom: 1px solid $border-color;
  padding: $space 0 $space * 0.5;

  &__inner {
    display: flex;
    flex-wrap: wrap;
    font-size: 0.9rem;
    list-style: none;
    margin-bottom: 0;
  }

  &__crumb {
    margin-bottom: $space * 0.5;

    &::after {
      color: $primary-text-color;
      content: ">";
      opacity: $opacity;
      padding: 0 0.5rem;
    }

    &:last-child {
      opacity: $opacity;

      &::after {
        content: none;
      }
    }
  }
}
