@import "variables";
// @import "mixins";
// @import "mq";

// Slick overrides
.slick-next::before,
.slick-prev::before {
  content: none;
}

.slick-prev {
  border-bottom: $space solid transparent;
  border-right: $space solid $primary-highlight-color;
  border-top: $space solid transparent;
  left: -30px;
}

.slick-next {
  border-bottom: $space solid transparent;
  border-left: $space solid $primary-highlight-color;
  border-top: $space solid transparent;
  right: -30px;
}
