ul.golf-list {
  list-style-type: none;
  li {
    h3 {
      font-size: 1.5rem;
      line-height: 2rem;
      margin-bottom: 0;

      &:before {
        content: "";
        height: $space/2;
        width: $space/2;
        background-color: $red;
        display: block;
        float: left;
        margin-top: $space/2;
        margin-right: $space;
      }

      &.red {
        &:before {
          background-color: $red;
        }
      }

      &.amber {
        &:before {
          background-color: $orange;
        }
      }

      &.green {
        &:before {
          background-color: $green;
        }
      }
    }

    p {
      font-size: 0.875rem;
    }
  }
}
