// Colours (Brand)
$foxhills-black: #0d060b;
$farleigh-blue: #002d5c;

// Colours (Accents)
$black: #000000;
$white: #ffffff;
$red: #fd2828;
$orange: #fd8e28;
$green: #3cd623;
$grey-mid: #eeeeee;
$grey-light: #c4c4c4;
$grey-lightest: #f7f7f7;
$grey-medium: #8b8d8d;
$grey-dark: #414141;

// Colours (Base)
$body-background-color: $white;
$primary-text-color: $foxhills-black;
$secondary-text-color: $foxhills-black;
$primary-highlight-color: var(--theme-color);
$secondary-highlight-color: var(--theme-color);
$tertiary-highlight-color: $grey-medium;
$primary-link-color: var(--theme-color);
$hover-link-color: $grey-medium;
$focus-color: var(--theme-color);
$focus-color-light: $white;
$focus-color-medium: $white;
$focus-color-dark: $white;
$border-color: $grey-light;
$border-color-dark: $white;
$border-color-medium: $white;
$button-border-color: var(--theme-color);
$hamburger-color: var(--theme-color);
$overlay: rgba(13, 6, 11, 0.1);
$primary-button-color: $black;
$secondary-button-color: $white;

// Colours (Text)
$heading-color: var(--theme-color);

// Colours (Forms)
$form-border-color: $foxhills-black;
$form-label-color: $primary-text-color;
$form-label-color-dark: $white;
$form-label-color-medium: $white;
$form-tooltip-color: var(--theme-color);
$form-placeholder-color: $grey-mid;
$form-focus-color: $grey-lightest;
$button-primary-color: var(--theme-color);
$error: $red;

// Colours (Footer)
$footer-background: $white;
$footer-text-color: $foxhills-black;

// Fonts
$base-font: "Century Gothic", sans-serif;
$heading-font: "Libre Baskerville", serif;

$baseline: 1.5rem;

$minScreen: 20rem;
$maxScreen: 80rem;
$maxContent: 45rem;
$minFont: 1rem;
$maxFont: 1rem;

$h1-lg: 3.5rem;
$h1-sm: 2.5rem;
$h2-lg: 2.5rem;
$h2-sm: 1.5rem;
$h3-lg: 2rem;
$h3-sm: 1.25rem;
$h4-lg: 1.5rem;
$h4-sm: 1rem;
$h5-lg: 1.5rem;
$h5-sm: 1rem;

// Spacing
$space: 20px;
$content-width: 90%; // Page width
$max-content-width: 1440px; // Maximum width before it just ends up centered (Containers will extend by default)
$header-height: 171px;
$mobile-header-height: 120px;

// Modifiers
$border-radius: $space/2;
$box-shadow: 0 10px 10px rgba($black, 0.025);
$opacity: 0.75;

//// Media Queries
// Uncomment this if your site isn't responsive. Enjoy your trip to the past!
// $mq-responsive: false;

// Responsive breakpoints, use semantic names
$mq-breakpoints: (
  XS: 320px,
  S: 480px,
  M: 720px,
  L: 980px,
  XL: 1024px,
  XXL: 1280px,
  XXXL: 1440px,
  mobile-menu: 1024px
);

// Define the breakpoint from the $mq-breakpoints list that IE8 should get
$mq-static-breakpoint: XL;

// If you want to display the currently active breakpoint in the top
// right corner of your site during development, add the breakpoints
// to this list, ordered by width, e.g. (mobile, tablet, desktop).
$mq-show-breakpoints: () !default;

// Animation Variables
$transition-speed: 0.3s;
$transition-timing: ease-in-out;
