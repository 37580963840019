.burger-menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-bottom: 100px;
  height: 100%;
  background-color: $grey-light;
  pointer-events: none;
  opacity: 0;
  color: $white;
  transition: opacity 150ms ease-in-out;
  z-index: 10000;
  overflow-y: auto;

  &.is-visible {
    opacity: 1;
    pointer-events: all;

    .burger-menu__inner {
      transform: translateY(0);
      transition: transform 200ms ease-in-out;
    }

    .burger-menu__main {
      > .burger-menu__menu > .burger-menu__item {
        > .burger-menu__link {
          pointer-events: all;
        }
      }
    }
  }

  &.second {
    .burger-menu__menu {
      transform: translateX(-100%);
    }
    .burger-menu__menu--second {
      transform: translateX(100%);
    }
    .burger-menu__menu--third {
      transform: translateX(100%);
    }
  }

  &.third {
    .burger-menu__menu {
      transform: translateX(-200%);
    }
    .burger-menu__menu--second {
      transform: translateX(100%);
    }
    .burger-menu__menu--third {
      transform: translateX(100%);
    }
  }

  &__inner {
    display: flex;
    position: relative;
    margin: 0 auto;
    flex-direction: column;
    justify-content: space-between;
    max-width: 550px;
    transform: translateY(-10%);
    transition: transform 900ms ease-in-out;
    will-change: transform;
    padding: 0 3%;

    @include mq($from: mobile-menu) {
      padding: 0;
    }
  }

  &__close {
    background-color: transparent;
    background-image: url("/img/dist/sprite/close.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    border: none;
    cursor: pointer;
    height: $space;
    line-height: $space;
    padding: 0;
    position: absolute;
    right: 1rem;
    text-indent: -9999px;
    transition: $transition-speed;
    top: 1rem;
    width: $space;
    z-index: 999;

    .theme--farleigh & {
      background-image: url("/img/dist/sprite/close-blue.svg");
    }

    &:hover,
    &:active {
      background-color: transparent;
      transform: rotate(90deg);
    }

    &:focus {
      background-color: none;
    }
  }

  &__logo {
    padding: $space 0;
    @include mq($from: mobile-menu) {
      text-align: center;
      padding: $space * 2 0;
    }

    svg,
    img {
      display: block;
      width: 100px;
      height: 24px;
      margin: 0 auto;
    }

    img {
      height: auto;
    }

    svg path {
      fill: $white;
    }
  }

  &__head {
    margin: 1em 0;
    display: flex;
    flex-direction: column;
  }

  &__search-container {
    display: flex;
    align-items: center;
    margin-top: 0.75em;
  }
  &__search {
    margin: 0;
    padding: 0 1em 0 3em;
    height: 53px;
    background-color: $grey-light;
    border: none;
    border-top: 1px solid rgba(0, 0, 0, 0.6);
    border-bottom: 1px solid rgba(0, 0, 0, 0.6);
    border-left: 1px solid rgba(0, 0, 0, 0.6);
    color: $black;
    background-image: url("/img/dist/sprite/search-black.svg");
    background-repeat: no-repeat;
    background-size: $space;
    background-position: 12px center;

    .theme--farleigh & {
      background-image: url("/img/dist/sprite/search-blue.svg");
    }

    &::-webkit-input-placeholder {
      /* Edge */
      color: $black;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $black;
    }

    &::placeholder {
      color: $black;
    }

    &:focus {
      outline: none;
      border-color: $white;
    }
  }
  &__search-btn {
    width: auto;
    margin: 0 !important;
  }

  &__menu {
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
    transition: transform 150ms ease-in-out;
    will-change: transform;

    &--second {
      transform: translateX(100%);
      opacity: 0;
      pointer-events: none;
    }

    &--third {
      transform: translateX(100%);
      opacity: 0;
      pointer-events: none;
    }

    &.is-open {
      opacity: 1;
      pointer-events: auto;

      .burger-menu__link {
        pointer-events: auto;
      }
    }
  }

  &__item {
    display: block;
    list-style: none;
    margin: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.6);
    font-family: $heading-font;
    font-size: 1rem;
    line-height: 1.25rem;

    @include mq($from: mobile-menu) {
      font-size: 1.25rem;
      line-height: 1.7rem;
    }

    &:last-child {
      border-bottom: 1px solid rgba(0, 0, 0, 0.6);
    }
  }

  &__main {
    flex-grow: 100;
    flex-shrink: 0;
    position: relative;
    overflow: hidden;
    min-height: 800px;

    > .burger-menu__menu > .burger-menu__item {
      > .burger-menu__link {
        pointer-events: none;
      }
    }
  }

  &__link {
    opacity: 1;
    padding: $space;
    display: block;
    color: var(--theme-color);
    text-decoration: none;
    text-transform: uppercase;
    transition: $transition-speed;
    position: relative;
    pointer-events: none;

    &.has-children {
      &:after {
        content: "";
        background-image: url("/img/dist/sprite/arrow-right-black.svg");
        background-repeat: no-repeat;
        background-position: right center;
        background-size: 100%;
        display: block;
        position: absolute;
        right: $space;
        top: 24px;
        height: 14px;
        width: 25px;
        transition: $transition-speed;

        .theme--farleigh & {
          background-image: url("/img/dist/sprite/arrow-right-blue.svg");
        }
      }

      &:hover {
        &:after {
          background-image: url("/img/dist/sprite/arrow-right-white.svg");
          right: 14px;
        }
      }
    }

    &--back {
      padding-left: $space * 3;

      &:after {
        content: "";
        background-image: url("/img/dist/sprite/arrow-right-black.svg");
        transform: rotate(180deg);
        background-repeat: no-repeat;
        background-position: right center;
        background-size: 100%;
        display: block;
        position: absolute;
        left: $space;
        top: 24px;
        height: 14px;
        width: 25px;
      }

      &:hover {
        &:after {
          background-image: url("/img/dist/sprite/arrow-right-white.svg");
        }
      }
    }

    &:hover {
      background: var(--theme-color);
      color: $white;
    }
  }

  &__buttons {
    @include mq($from: mobile-menu) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 0.75em;
    }
  }

  .btn {
    display: block;
    text-align: center;
    color: $secondary-button-color;
    font-weight: bold;
    background-color: var(--theme-color);
    text-decoration: none;
    text-transform: uppercase;
    margin: $space 0;
    padding: $space/1.5;
    border: 1px solid var(--theme-color);

    &:hover {
      background-color: transparent;
      border-color: var(--theme-color);
      color: var(--theme-color);
    }
  }

  &__vouchers {
    border: 1px solid $white;
    padding: $space * 1.5;
    background-image: url("/img/dist/pattern.png");
    position: relative;
    margin-top: $space;
    margin-bottom: $space * 3;

    &:after {
      content: "";
      display: block;
      background-image: url("/img/dist/sprite/icon-gift-white.svg");
      background-repeat: no-repeat;
      background-size: contain;
      height: $space;
      width: $space;
      position: absolute;
      top: $space;
      right: $space;
    }

    h2 {
      font-size: 1.25rem;
      color: $white;
      margin-bottom: $space/2;

      a {
        color: $white;
      }
    }
    p {
      margin-bottom: 0;
      font-size: 0.75rem;
      line-height: 1rem;
    }
  }

  &__foot-links {
    padding: 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__foot-link {
    padding: $space/2 $space;
    text-decoration: none;
    text-transform: uppercase;
    color: var(--theme-color);

    @include mq($from: L) {
      padding: $space;
    }

    &:hover {
      color: $grey-medium;
    }

    &:active {
      color: $grey-medium;
    }

    &:focus {
      color: $grey-dark;
    }
  }
}
