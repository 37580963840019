.hamburger {
  margin-top: 4px;
  border: none;
  background: transparent;
  display: block;
  width: 32px;
  height: 36px;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  z-index: 9999;
  top: 10px;
  right: 0;

  &:hover,
  &:active,
  &:focus {
    background: transparent;
  }

  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: var(--theme-color);
    border-radius: 2px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }

  span.hamburger__menu {
    background: transparent;
    color: var(--theme-color);
    text-transform: uppercase;
    bottom: 0;
    font-size: 0.7rem;
    font-weight: 700;
  }
}

.hamburger span:nth-child(2) {
  top: 0px;
  transform-origin: left center;
}

.hamburger span:nth-child(3) {
  top: 12px;
  transform-origin: left center;
}

.hamburger span:nth-child(4) {
  top: 24px;
  transform-origin: left center;
}

.hamburger.open span:nth-child(2) {
  transform: rotate(45deg);
  top: 0;
  left: 6px;
  background: var(--theme-color);
}

.hamburger.open span:nth-child(3) {
  width: 0;
  opacity: 0;
}

.hamburger.open span:nth-child(4) {
  transform: rotate(-45deg);
  top: 28px;
  left: 7px;
  background: var(--theme-color);
}
