@import "../../variables";
@import "../../mixins";
@import "../../mq";

.team-block {
  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: $space $space 0;
    width: calc(100% + #{$space});

    .content-panel__row--two-column & {
      display: block;
    }

    @include mq($from: M) {
      margin-bottom: -$space * 2;
      margin-left: -$space * 0.5;
      padding: $space * 2 0 0 $space * 2;
    }

    @include mq($from: L) {
      margin-left: -$space;
      width: calc(100% + #{$space * 2});
    }
  }

  &__item {
    border: 1px solid $border-color;
    display: flex;
    flex-direction: column;
    margin-bottom: $space * 3;
    position: relative;

    @include mq($from: M) {
      margin-left: $space * 4;
      margin-bottom: $space * 4;
    }

    @include mq($from: M, $until: L) {
      width: calc(50% - #{$space * 2});

      &:nth-child(odd) {
        margin-left: 0;
      }
    }

    @include mq($from: L, $until: XXL) {
      width: calc(33.3333% - 54px);

      &:nth-child(3n + 1) {
        margin-left: 0;
      }
    }

    @include mq($from: XXL) {
      width: calc(25% - #{$space * 3});

      &:nth-child(4n + 1) {
        margin-left: 0;
      }
    }

    &:last-child {
      @include mq($until: M) {
        margin-bottom: 0;
      }
    }

    .panel--theme-medium &,
    .panel--theme-dark & {
      border-color: $white;
    }

    .content-panel__row--two-column & {
      margin-bottom: $space * 2;
      width: auto;

      @include mq($from: M) {
        width: auto;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__image-container {
    height: auto;
    margin: #{-$space} 0 0 #{-$space * 2};
    padding-left: $space;
    width: calc(100% + #{$space});

    @include mq($from: M) {
      margin: #{-$space * 2} 0 0 #{-$space * 2};
      padding: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__image {
    @include object-fit(cover, center);
    display: block;
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
    z-index: 2;
    position: relative;
  }

  &__job-title {
    @include headingFont($h5-sm, $h5-lg);
  }

  &__content {
    background-color: $white;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: #{-$space} 0 #{$space} #{-$space * 2};
    padding: $space $space * 2 $space $space;
    position: relative;
    width: calc(100% + #{$space * 2});
    z-index: 2;

    @include mq($from: M) {
      margin: #{$space} 0 #{$space} #{-$space * 2};
    }

    @include mq($from: XXL) {
      margin-bottom: $space * 2;
      margin-top: $space * 2;
    }

    p:last-child {
      margin-bottom: 0;
    }

    .panel--theme-light & {
      background-color: $grey-lightest;
    }

    .panel--theme-medium & {
      background-color: $grey-medium;
    }

    .panel--theme-dark & {
      background-color: $foxhills-black;
    }
  }

  &__heading,
  &__job-title {
    .panel--theme-medium &,
    .panel--theme-dark & {
      color: $white;
    }
  }

  &__heading {
    margin-bottom: $baseline * 0.5;
  }

  &__actions {
    align-items: center;
    display: flex;
    padding-top: $baseline * 1.5;

    a {
      margin-left: 1.5rem;

      &:first-child {
        margin-left: 0;
      }

      .panel--theme-medium &,
      .panel--theme-dark & {
        color: $white;
      }
    }
  }

  &__icon {
    fill: var(--theme-color);
    height: 28px;
    width: 28px;

    &--email {
      height: 24px;
      width: 32px;
    }

    .panel--theme-medium &,
    .panel--theme-dark & {
      fill: $white;
    }
  }

  .content-panel__row--two-column .content-panel__column & {
    @include mq($from: M) {
      padding: $space * 2;
    }

    @include mq($from: XXL) {
      padding: $space * 4;
    }
  }
}
