.video-modal {
  position: relative;

  &__button {
    border-bottom: 1px solid $white;
    color: $white;
    display: inline-block;
    font-family: $heading-font;
    font-size: 1.125rem;
    padding-bottom: $space * 0.5;
    position: relative;
    transition: border-color $transition-speed $transition-timing;
    text-shadow: 0px 0px 14px #000000;

    svg {
      width: 9px;
      margin-top: -5px;
      height: 14px;
      margin-right: 13px;
      fill: $white;
      filter: drop-shadow(0px 0px 7px #000000);
    }

    &:hover,
    &:focus {
      border-color: transparent;
      color: $white;
    }
  }

  &__popup {
    background: rgba(0, 0, 0, 0.7);
    padding: $space * 2;
    text-align: center;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 10000;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    transition: $transition-speed;

    &.open {
      opacity: 1;
      pointer-events: all;
    }
  }

  &__inner {
    position: relative;
    width: 100%;

    @include mq($from: XL) {
      width: 70%;
    }
  }

  &__close {
    position: absolute;
    top: -$space * 2;
    right: 0;
    width: $space;
    height: $space;

    @include mq($from: XL) {
      right: -$space * 2;
    }

    svg {
      width: $space;
      height: $space;
      fill: $white;
      transition: $transition-speed;
    }

    &:hover {
      svg {
        transform: rotate(90deg);
      }
    }
  }

  &__video {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
  }

  &__iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
