.venues-panel {
  padding-bottom: $space * 2;

  @include mq($from: L) {
    padding-bottom: $space * 4;
  }

  &__content {
    display: flex;
    align-items: flex-start;
    margin-bottom: $space * 2;

    @include mq($from: XL) {
      margin-bottom: $space * 3;
    }
  }

  &__status {
    flex-basis: 380px;
    flex-shrink: 0;
    margin-left: 60px;
    border: 1px solid $border-color;
  }
  &__status-inner {
    padding: 30px;
    border-bottom: 1px solid $border-color;

    &:last-child {
      border-bottom: none;
    }

    h3 {
      margin: 0;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }
  &__carousel {
    margin: 0 -15px;

    .slick-track {
      display: flex !important;
    }

    .slick-slide {
      height: inherit !important;

      > div {
        height: 100%;
      }
    }

    .slick-prev,
    .slick-next {
      z-index: 100;
      border: 0;
      top: 50%;
      height: 24px;
      width: 12px;

      @include mq($from: L) {
        height: 48px;
        width: 24px;
      }

      &:hover {
        opacity: 0.7;
      }
    }

    .slick-disabled {
      opacity: 0.5;
    }

    .slick-prev {
      left: -2px;
      background: url(/img/dist/sprite/slider-arrow-left-black.svg) no-repeat;
      background-size: contain;
      border: 0;

      @include mq($from: L) {
        left: -$space * 1.5;
      }

      @include mq($from: XXXL) {
        left: -$space * 2;
      }
    }

    .slick-next {
      right: -2px;
      background: url(/img/dist/sprite/slider-arrow-right-black.svg) no-repeat;
      background-size: contain;
      border: 0;

      @include mq($from: L) {
        right: -$space * 1.5;
      }

      @include mq($from: XXXL) {
        right: -$space * 2;
      }
    }
  }
  &__carousel-item {
    height: 100%;
    padding: 0 15px;
    text-align: center;

    @include mq($from: L) {
      padding: 0 5px;
    }
  }
}
