.status-row {
  display: flex;
  justify-content: space-between;

  &__value {
    font-weight: bold;

    &--red {
      color: $red;
    }
    &--amber {
      color: $orange;
    }
    &--green {
      color: $green;
    }
  }
}
