@import "../variables";
// @import "../mixins";
@import "../mq";

.primary-header {
  background-color: $white;
  box-shadow: $box-shadow;
  position: fixed;
  top: 0;
  transition: all $transition-speed $transition-timing;
  width: 100%;
  z-index: 9999;
  display: none;

  @include mq($from: mobile-menu) {
    display: block;
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 112px;
    position: relative;
    flex-wrap: wrap;
  }

  &__icon {
    fill: var(--theme-color);
    margin-bottom: -80px;
    position: relative;
    margin-top: -120px;
    width: 92px;

    // White block behind CJ
    &:before {
      content: "";
      background-color: $white;
      position: absolute;
      width: 60px;
      left: 5px;
      height: 70px;
      top: -20px;
      z-index: 1;
    }

    // White circle behind CJ
    &:after {
      content: "";
      background-color: $white;
      position: absolute;
      width: 102px;
      bottom: -20px;
      height: 102px;
      left: 0;
      z-index: 1;
      border-radius: 100%;
    }

    svg {
      width: 100%;
      position: relative;
      z-index: 2;
    }
  }

  &__logo {
    position: relative;
    width: 45%;
    z-index: 2;
  }

  &__logo-link {
    display: block;
    height: $space * 2;
    width: 92px;

    .theme--farleigh & {
      height: auto;
    }

    @include mq($from: S) {
      height: $space * 2.5;
      width: 149px;
    }

    &:focus {
      outline: $focus-color dotted 1px;
      outline-offset: 0;
    }
  }

  &__logo-image {
    display: block;
    height: 100%;
    width: 100%;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    position: fixed;
  }
}
