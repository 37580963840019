.status-panel {
  &__content {
    margin-bottom: $space * 2;

    @include mq($from: L) {
      display: flex;
      align-items: flex-start;
    }

    @include mq($from: XL) {
      margin-bottom: $space * 3;
    }
  }
  &__status {
    flex-basis: 380px;
    flex-shrink: 0;
    border: 1px solid $border-color;

    @include mq($from: L) {
      margin-left: 40px;
    }
    @include mq($from: XXL) {
      margin-left: 60px;
    }
  }
  &__status-inner {
    padding: 20px;
    border-bottom: 1px solid $border-color;

    @include mq($from: L) {
      padding: 30px;
    }

    &:last-child {
      border-bottom: none;
    }

    h3 {
      margin: 0;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }
}
