@import "../../variables";
// @import "../../mixins";
// @import "../../mq";

.iframe-block {
  max-width: 100%;

  &__iframe {
    border: 0;
    display: block;
    height: calc(100vh - #{$mobile-header-height});
    margin: auto;
    min-width: 100%;
    width: 1px;

    @include mq($from: XL) {
      height: calc(100vh - #{$header-height});
    }
  }
}
