@import "../../variables";
// @import "../../mixins";
@import "../../mq";

$icon-size: $space * 1.5;

.features-block {
  margin-bottom: -$space * 2;
  text-align: center;

  &__items {
    list-style: none;

    @include mq($from: S) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    li {
      margin-bottom: $space * 2;
      text-align: center;

      @include mq($from: S) {
        margin-left: $space;
        width: calc(50% - #{$space * 0.5});

        &:nth-child(odd) {
          margin-left: 0;
        }

        &:last-child {
          margin-bottom: $space * 2;
        }
      }

      @include mq($from: M) {
        width: calc(33% - 12px);

        &:nth-child(odd) {
          margin-left: $space;
        }

        &:nth-child(3n + 1) {
          margin-left: 0;
        }

        .content-panel__row--two-column & {
          width: 100%;

          &:nth-child(odd) {
            margin-left: 0;
          }
        }
      }

      @include mq($from: L) {
        width: calc(25% - 15px);

        &:nth-child(3n + 1) {
          margin-left: $space;
        }

        &:nth-child(4n + 1) {
          margin-left: 0;
        }

        .content-panel__row--two-column & {
          width: calc(50% - #{$space * 0.5});

          &:nth-child(3n + 1) {
            margin-left: 0;
          }
        }
      }

      @include mq($from: XXL) {
        width: calc(20% - 16px);

        &:nth-child(4n + 1) {
          margin-left: $space;
        }

        &:nth-child(5n + 1) {
          margin-left: 0;
        }

        .content-panel__row--two-column & {
          width: calc(33% - 12px);

          &:nth-child(odd) {
            margin-left: $space;
          }

          &:nth-child(4n + 1) {
            margin-left: 0;
          }
        }
      }
    }
  }

  &__icon-container {
    align-items: center;
    background-color: $primary-highlight-color;
    border-radius: 100%;
    display: flex;
    height: 70px;
    justify-content: center;
    margin: 0 auto $baseline;
    overflow: hidden;
    width: 70px;

    .panel--theme-medium &,
    .panel--theme-dark & {
      background-color: $white;
    }
  }

  &__icon {
    display: block;
    min-height: $icon-size;
    margin-bottom: 0;
    min-width: $icon-size;

    &--svg {
      background-color: $white;
      height: $icon-size;
      mask: var(--icon) center no-repeat;
      width: $icon-size;

      .panel--theme-light & {
        background-color: $grey-lightest;
      }

      .panel--theme-medium & {
        background-color: $tertiary-highlight-color;
      }

      .panel--theme-dark & {
        background-color: $secondary-highlight-color;
      }
    }
  }

  &__title {
    letter-spacing: 0;
    margin-bottom: $baseline * 0.5;
    margin-top: 0;
    font-size: 1.75rem;
    line-height: 2.25rem;

    .panel--theme-medium &,
    .panel--theme-dark & {
      color: $white;
    }
  }

  &__content {
    p {
      color: $primary-text-color;
      font-size: 0.8rem;
      line-height: 1.5;

      &:last-child {
        margin-bottom: 0;
      }

      .panel--theme-medium &,
      .panel--theme-dark & {
        color: $white;
      }
    }
  }

  .content-panel__row--two-column .content-panel__column & {
    @include mq($from: M) {
      padding: $space * 2;
    }

    @include mq($from: XXL) {
      padding: $space * 4;
    }
  }
}
