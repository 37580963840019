@use "sass:math";

@import "../../variables";
// @import "../../mixins";
@import "../../mq";

.stats-block {
  text-align: center;

  @include mq($from: XL) {
    margin-bottom: -$space * 2;
  }

  &__items {
    list-style: none;

    @include mq($from: S) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    li {
      margin-bottom: $space * 2;
      text-align: center;

      &:last-child {
        margin-bottom: 0;
      }

      @include mq($from: S) {
        margin-left: $space;
        width: calc(50% - #{$space * 1});

        &:nth-child(odd) {
          margin-left: 0;
        }

        &:last-child {
          margin-bottom: $space * 3;
        }
      }

      @include mq($from: M) {
        width: calc(33% - 12px);

        &:nth-child(odd) {
          margin-left: $space;
        }

        &:nth-child(3n + 1) {
          margin-left: 0;
        }

        .content-panel__row--two-column & {
          width: calc(50% - #{$space * 0.5});
        }
      }

      @include mq($from: L) {
        width: calc(25% - 15px);

        &:nth-child(3n + 1) {
          margin-left: $space;
        }

        &:nth-child(4n + 1) {
          margin-left: 0;
        }

        .content-panel__row--two-column & {
          width: calc(33% - 12px);

          &:nth-child(odd) {
            margin-left: $space;
          }

          &:nth-child(3n + 1) {
            margin-left: 0;
          }
        }
      }

      @include mq($from: XXL) {
        width: calc(20% - 16px);

        &:nth-child(4n + 1) {
          margin-left: $space;
        }

        &:nth-child(5n + 1) {
          margin-left: 0;
        }

        .content-panel__row--two-column & {
          width: calc(33% - 12px);

          &:nth-child(odd) {
            margin-left: $space;
          }

          &:nth-child(3n + 1) {
            margin-left: 0;
          }
        }
      }
    }
  }

  &__title {
    font-weight: 400;
  }

  &__content {
    p {
      color: $primary-text-color;
      font-size: 1rem;
      line-height: 1.5rem;
      font-family: $heading-font;

      &:last-child {
        margin-bottom: 0;
      }

      .panel--theme-medium &,
      .panel--theme-dark & {
        color: $white;
      }
    }
  }

  &__statistic {
    @include headingFont($h3-sm, $h3-lg);
    border-bottom: 1px solid $border-color;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: $baseline * 0.5;
    padding-bottom: $baseline * 0.5;

    .panel--theme-medium &,
    .panel--theme-dark & {
      color: $white;
    }
  }

  .content-panel__row--two-column .content-panel__column & {
    @include mq($from: M) {
      padding: $space * 2;
    }

    @include mq($from: XXL) {
      padding: $space * 4;
    }
  }
}
