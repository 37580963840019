@import "../../variables";
@import "../../mixins";
@import "../../mq";

.gallery-block {
  display: flex;
  flex-wrap: wrap;
  position: relative;

  @include mq($from: L) {
    margin: -1.25rem 0 0;
  }

  @include fluid-type(
    margin-left margin-right,
    $minScreen,
    $maxScreen,
    -0.625rem,
    -1.25rem
  );

  &__image-container {
    @include fluid-type(padding, $minScreen, $maxScreen, 0.625rem, 1.25rem);

    @include mq($from: S) {
      width: 50%;
    }

    @include mq($from: M) {
      width: 33%;

      .content-panel__row--two-column & {
        width: 50%;
      }
    }

    @include mq($from: XXL) {
      width: 25%;
    }

    .panel--theme-light & {
      &:focus {
        outline-color: $focus-color-light;
      }
    }

    .image-grid-block__items--as-carousel & {
      margin-bottom: 0;
      width: auto;
    }
  }

  &__image {
    @include object-fit(cover, center);
    display: block;
    height: 100%;
    margin: 0;
    padding: 0;
    transition: opacity $transition-speed $transition-timing;
    width: 100%;

    a:hover & {
      opacity: $opacity;
    }
  }

  .content-panel__row--two-column .content-panel__column & {
    margin: 0;

    @include mq($from: M) {
      padding: $space * 0.5;
    }

    @include mq($from: L) {
      margin: 0;
      padding: $space;
    }
  }

  // As a carousel
  &--as-carousel {
    display: block;
    margin: 0 -10%;

    @include mq($from: L) {
      margin: 0;
    }

    &.slick-dotted.slick-slider {
      margin-bottom: 50px;
    }

    .slick-prev,
    .slick-next {
      z-index: 100;
      border: 0;
      top: 50%;
      height: 48px;
      width: 24px;

      &:hover {
        opacity: 0.7;
      }
    }

    .slick-prev {
      left: -$space * 1.5;
      background: url(/img/dist/sprite/slider-arrow-left-black.svg) no-repeat;
      background-size: contain;
      border: 0;

      @include mq($from: XXXL) {
        left: -$space * 2;
      }
    }

    .slick-next {
      right: -$space * 1.5;
      background: url(/img/dist/sprite/slider-arrow-right-black.svg) no-repeat;
      background-size: contain;
      border: 0;

      @include mq($from: XXXL) {
        right: -$space * 2.5;
      }
    }

    .slick-dots {
      bottom: -$space * 2.5;

      button:before {
        height: 12px;
        width: 12px;
        opacity: 1;
      }
    }

    .slick-dots li button:before {
      background-color: $white;
      border: 1px solid rgba(13, 6, 11, 0.3);
      content: "";
      border-radius: 100%;
    }

    .slick-dots li.slick-active button:before {
      background-color: $black;
    }

    .gallery-block__image-container {
      height: 350px;

      @include mq($from: S) {
        height: 400px;
      }

      @include mq($from: M) {
        height: 500px;

        .content-panel__row--two-column & {
          width: 50%;
        }
      }

      @include mq($from: XXL) {
        height: 760px;
      }
    }
  }

  .panel--theme-medium &,
  .panel--theme-dark & {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $white;
    }

    a {
      color: $white;
      border-color: $white;
    }

    svg {
      stroke: $white;
    }

    a.btn {
      &--primary {
        background-color: $secondary-button-color;
        border-color: $secondary-button-color;
        color: $primary-button-color;

        &:hover {
          background-color: transparent;
          color: $secondary-button-color;
        }
      }
    }

    .slick-prev {
      background: url(/img/dist/sprite/slider-arrow-left.svg) no-repeat;
    }

    .slick-next {
      background: url(/img/dist/sprite/slider-arrow-right.svg) no-repeat;
    }
  }
}
