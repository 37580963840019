// @import "../../variables";
// @import "../../mixins";
@import "../../mq";

.map-block {
  &__map {
    height: 300px;
    width: 100%;

    .content-panel__row--two-column & {
      height: 260px;
    }

    @include mq($from: M) {
      height: 400px;

      .content-panel__row--two-column & {
        height: 320px;
      }
    }

    @include mq($from: L) {
      height: 600px;

      .content-panel__row--two-column & {
        height: 400px;
      }
    }
  }
}

.map-container {
  display: flex;
  flex-direction: column;
  height: 80vh;
}

.map-iframe-container {
  position: relative;
  width: 100%;
  flex: 1;
  display: flex;
}

.map-iframe-container > iframe {
  border: 0;
  width: 100%;
  flex: 1;
}

@media only screen and (max-width: 600px) {
  .map-container {
    height: 60vh;
  }
}
