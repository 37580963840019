.nav-wrapper {
  &--hide-mobile {
    position: relative;
    display: none;
    @include mq($from: XL) {
      display: block;
    }
  }

  &--hide-desktop {
    display: block;
    position: relative;
    @include mq($from: XL) {
      display: none;
    }
  }
}

.nav-slider {
  &--basic {
    border-bottom: 1px solid #c4c4c4;
  }
  &--slide-down {
    border-bottom: 1px solid #c4c4c4;
    margin-bottom: 0 !important;
  }
  .slick-slide {
    //    border-right: 2px solid white;
    //    border-left: 2px solid white;

    .nav-slider__overlay {
      opacity: 0.8;
      transition: 0.5s;
    }

    &.slick-active {
      .nav-slider__overlay {
        transition: 0.5s;
        opacity: 0.4;
      }
    }

    &.slick-current .nav-slider__link {
      font-weight: 700;
      border-bottom: 1px solid $black;
    }
  }

  &__overlay {
    transition: 0.5s;
    background: black;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    pointer-events: none;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 40px;
    padding: 0 6px;
    border-right: 1px solid #c4c4c4;
    margin: 14px 0;

    @include mq($from: L) {
      padding: 0 8px;
      height: 50px;
    }

    .nav-slider__link {
      text-transform: uppercase;
      font-family: $heading-font;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.2rem;
      margin: 0;

      &:hover {
        color: $hover-link-color;
      }

      @include mq($from: L) {
        font-size: 1rem;
        line-height: 1.5rem;
      }

      &.open {
        font-weight: 700;
        border-bottom: 1px solid $black;
      }
    }
  }

  &__image {
    position: relative;
    height: auto;
    display: none;

    @include mq($from: XL) {
      height: 650px;
      display: block;
    }

    img {
      @include object-fit(cover, center);
      display: block;
      height: 100%;
      margin: 0;
      padding: 0;
      transition: $transition-speed;
      width: 100%;
    }
  }

  .slick-prev,
  .slick-next {
    z-index: 100;
    border: 0;
    bottom: auto;
    top: 34px;
    height: 50px;
    width: 36px;
    background-color: $white;

    @include mq($from: L) {
      top: 40px;
    }

    .nav-slider--basic & {
      top: 50%;
      @include mq($from: L) {
        top: 50%;
      }
    }
  }
  .slick-prev {
    left: 0;
    background-image: url(/img/dist/sprite/slide-arrow-left-black.svg);
    background-repeat: no-repeat;
    background-size: 30%;
    background-position: center;
  }

  .slick-next {
    right: 0;
    background-image: url(/img/dist/sprite/slide-arrow-right-black.svg);
    background-repeat: no-repeat;
    background-size: 30%;
    background-position: center;
  }

  .slick-dots {
    bottom: -$space * 3;

    button:before {
      height: 12px;
      width: 12px;
      opacity: 1;
    }
  }

  .slick-dots li button:before {
    background-color: $white;
    border: 1px solid rgba(13, 6, 11, 0.3);
    content: "";
    border-radius: 100%;
  }

  .slick-dots li.slick-active button:before {
    background-color: $black;
  }
}

.nav-slider .slick-center,
.nav-slider
  .slick-slide[aria-hidden="true"]:not([tabindex="-1"])
  + .slick-cloned[aria-hidden="true"] {
  .nav-slider__overlay {
    opacity: 0.4;
  }
}
