.cookieOpenButton {
  background-color: unset !important;
  bottom: 0;
  content: "";
  height: 80px;
  outline: unset !important;
  position: fixed;
  width: 80px;
  z-index: 2147483646;

  &__background {
    border-bottom: 80px solid var(--theme-color);
    fill: white;
  }

  svg {
    bottom: 10px;
    fill: white;
    height: 25px;
    position: fixed;
    width: 25px;
  }

  &--left {
    left: 0;

    .cookieOpenButton__background {
      border-right: 80px solid transparent;
    }

    svg {
      left: 10px;
    }
  }

  &--right {
    right: 0;

    .cookieOpenButton__background {
      border-left: 80px solid transparent;
    }

    svg {
      right: 10px;
    }
  }
}

body #ccc {
  font-family: inherit;
  font-size: 1rem;
  z-index: 2147483647;

  #ccc-notify-dismiss {
    display: none;
  }

  #ccc-notify-title {
    margin-bottom: $baseline * 0.25;
  }

  #ccc-title,
  .ccc-title {
    @include headingFont($h5-sm, $h5-lg);
  }

  h2,
  h3 {
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: -1px;
    line-height: 1.25;
    margin-bottom: $baseline * 0.5;
    margin-top: 0;
  }

  .ccc-svg-element {
    top: auto;
  }

  .ccc-notify-buttons {
    margin-top: 0;
  }

  #ccc-notify-accept,
  #ccc-notify-reject,
  #ccc-dismiss-button,
  #ccc-recommended-settings,
  #ccc-reject-settings {
    @extend %btn;
    margin: 0 1rem 0 0;
    padding: 0 !important;

    &,
    span {
      transition: all $transition-speed $transition-timing;
    }

    span {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding: 6px 8px;
      position: relative;
      z-index: 1;

      @include mq($from: S) {
        padding: 12px $space;
      }
    }
  }

  #ccc-notify-accept,
  #ccc-recommended-settings {
    background-color: $primary-link-color;

    &,
    span {
      color: $white;
    }

    &:hover {
      &,
      span {
        background-color: $white;
        color: $primary-link-color;
      }
    }
  }

  #ccc-notify-reject,
  #ccc-dismiss-button,
  #ccc-reject-settings {
    border: 2px solid $primary-link-color;

    &,
    span {
      background-color: transparent;
      color: $primary-link-color;
    }

    &:hover {
      border-color: $hover-link-color;

      &,
      span {
        background-color: transparent;
        color: $hover-link-color;
      }
    }
  }

  #ccc-notify-reject,
  .ccc-notify-button {
    margin: 0 1rem 0 0;
  }

  .ccc-notify-button {
    padding: 0 !important;

    &:hover {
      text-decoration: none;
    }
  }

  #ccc-button-holder {
    padding-top: $baseline * 0.5;

    #ccc-reject-settings {
      display: none;
    }
  }

  .checkbox-toggle--slider.checkbox-toggle--light {
    background-color: $border-color;
    border-color: transparent;

    .checkbox-toggle-on,
    .checkbox-toggle-off {
      color: $primary-highlight-color;
    }

    .checkbox-toggle-toggle {
      background-color: $primary-highlight-color;
    }
  }

  .ccc-content--light {
    background-color: $white;
  }

  #ccc-close svg {
    fill: var(--theme-color);
  }
}
